.title-container {
  padding: 16px 8px 0px 8px;
  padding-right: 0;
  // width: 100%;
  max-width: calc(100% - 16px);
  .title-color {
    color: #888888;
  }
  .title {
    color: #888888;
    padding: 8px 8px 0 0;
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 400;
  }
  .black {
    color: black;
  }

  .title.h1 {
    font-size: 40px;
  }

  .title.h2 {
    font-size: 32px;
  }

  .title.h3 {
    font-size: 24px;
  }

  .title.h4 {
    font-size: 18px;
  }

  .title.h5 {
    font-size: 15px;
  }

  @media screen and (max-width: 599px) {
    .title {
      line-height: 40px;
    }

    .title.h1 {
      font-size: 32px;
      line-height: 40px;
    }

    .title.h2 {
      font-size: 28px;
      line-height: 20px;
    } 

    .title.h3 {
      font-size: 16px;
      line-height: 20px;
    }

    .title.h4 {
      font-size: 14px;
      line-height: 20px;
    }

    .title.h5 {
      font-size: 13px;
      line-height: 20px;
    }
  }
}

.upper {
  text-transform: uppercase;
}

.capital {
  text-transform: capitalize;
}

.title-container.padding-fix {
  padding-left: 8px;
  padding-top: 0;
  max-width: calc(100% - 32px);
  .title {
    margin: 0;
  }
}

.title-container.no-padding, .title-container.no-padding > * {
  padding: 0;
  margin-bottom: 8px;
}


.title-container {
  app-backlink .voltar {
    margin-left: 0px;
  }
}

.title-container, .title-container-wrapper {
  .title-container, .title-container.padding-fix {
    max-width: unset;
  }
}
