@use '@angular/material' as mat;
@import 'color-mixins';
@import '../variables';
@import '../../app/components/sidemenu/item/sideitem.component.theme';
@import 'typography.theme';
// @import 'quill-editor.theme';
@import 'sidenav.theme';
@import 'snackbar.theme';
// @import '~@covalent/core/theming/all-theme';
// @import '../../app/pages/chat-page/chat-page.component.theme';
// @import '../../app/pages/pricing-tables-page/pricing-tables-page.component.theme';

/************************************** MATERIAL BASIC STYLES **************************************/
@include mat.core();

/************************************** LIGHT THEME **************************************/
@include mat.all-component-themes($light-app-theme);
// @include covalent-theme($light-app-theme);
//Application custom theme style
@include all-colors($light-app-theme);
@include typography-theme($light-app-theme);
// @include chat-page-theme($light-app-theme);
// @include pricing-tables-theme($light-app-theme);

@include side-item-theme($dark-app-theme);
@include sidenav-theme($dark-app-theme);
@include snackbar-theme($dark-app-theme);
/************************************** DARK THEME **************************************/
.dark-theme {
  @include mat.all-component-themes($dark-app-theme);
  // @include covalent-theme($dark-app-theme);
  //Application custom theme style
  @include all-colors($dark-app-theme);
  @include typography-theme($dark-app-theme);
  // @include chat-page-theme($dark-app-theme);
  // @include pricing-tables-theme($dark-app-theme);
  //Style for editor added oly for dark theme, because default style already present are for light theme
  // @include quill-editor-theme($dark-app-theme);
  @include side-item-theme($light-app-theme);
  @include sidenav-theme($light-app-theme);

  .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    color: #FFFFFF
  }
} 

