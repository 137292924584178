@import "./../../../../styles/variables";
@import "./../../../../styles/theme/color-mixins";

@mixin side-item-theme($theme) {
  li {
    .item {
      a:-webkit-any-link {
        color: $dark-primary-text-color;
      }
      a {
        &.active {
          color: primary-color($theme);
        }
      }
    }
    .item {
      &:hover {
        color: primary-color($theme);
      }
    }
    &.open {
      > .item {
        color: primary-color($theme);

      }
    }
  }
  .menu-level-0 .children {
    background-color: background-color-2($theme)
  }
  .menu-level-1 .children {
    background-color: background-color-3($theme)
  }
  .menu-level-2 .children {
    background-color: background-color-4($theme)
  }
}
