.flex-container.columns {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    max-height: 100%;
    place-content: flex-start space-between;
    align-items: flex-start;
    &.stretch {
        place-content: stretch space-between;
        align-items: stretch;
    }
}
.flex-container.column {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    max-width: 100%;
    place-content: flex-start;
    align-items: flex-start;
    width: 100%;
    &.space-between {
        place-content: stretch space-between;
        align-items: stretch;
    }
    &.stretch {
        place-content: stretch flex-start;
        align-items: stretch;
    }
}

.flex-container {
    .columns {
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        max-height: 100%;
        place-content: flex-start space-between;
        align-items: flex-start;
        &.stretch {
            place-content: stretch space-between;
            align-items: stretch;
        }
    }
    .column {
        flex-direction: column;
        box-sizing: border-box;
        display: flex;
        max-width: 100%;
        place-content: flex-start;
        align-items: flex-start;
        width: 100%;
        &.space-between {
            place-content: stretch space-between;
            align-items: stretch;
        }
        &.stretch {
            place-content: stretch flex-start;
            align-items: stretch;
        }
    }
}