@import 'color-mixins';

@mixin sidenav-theme($theme) {
  $backgroundColor: background-color($theme);
  .mat-sidenav-container {
    .mat-sidenav {
      background-color: $backgroundColor;
      px-gdn-sideitem div.item, px-gdn-sideitem i {
        background-color: $backgroundColor;
        // color: white;
        color: #303030;
      }
      // px-gdn-sideitem div.item :hover {
      //   color: accent-color($theme);
      // }
    }
  }
}
