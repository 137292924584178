.svgicon, .svgicon.mat-icon {
    width: 20px;
    height: 20px;
    padding-top: 8px;
}

.svgicon:not(.color-icon-override), .svgicon.mat-icon:not(.color-icon-override) {
    fill: currentColor;
    svg * {
      fill: currentColor;
    }
}

.svgicon.fill.mat-icon {
    width: 100%;
    height: 100%;
}

.svgicon.titan.mat-icon {
    width: 256px;
    height: 246px;
}

.svgicon.colossal.mat-icon {
    width: 128px;
    height: 128px;
}

.svgicon.macro.mat-icon {
    width: 64px;
    height: 64px;
}
  
.svgicon.big.mat-icon {
    width: 24px;
    height: 24px;
}
  
.svgicon.small.mat-icon {
    width: 16px;
    height: 16px;
    padding-top: 4px;
}
  
.svgicon.micro.mat-icon {
    width: 12px;
    height: 12px;
    padding-top: 4px;
  }
  
.svgicon.nano.mat-icon {
    width: 10px;
    height: 10px;
    padding-top: 4px;
}
  
.dark-theme li .item a.active {
    svg path {
      fill: #2196F3;
    }
}

.svgicon.primary-color {
    svg path {
      fill: #2196F3;
    }
}
  
.svgicon.white {
    svg path {
      fill: white;
    }
}
  
.svgicon.grey {
    svg path {
      fill: #cacaca;
    }
}
  
.svgicon.black {
    svg path {
      fill: black;
    }
}

.svgicon.mat-icon.fa {
    height: 48px;
    width: 48px;
    font-size: 22px;
    margin-top: -4px;
    margin-left: 2px;
}

.svgicon.mat-icon.material-icons {
    padding-top: 0;
}

