@charset "UTF-8";
@import '~normalize.css/normalize.css';
@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
/************************************** FONT DEFINITION **************************************/
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
/************************************** TEXT STYLES **************************************/
body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-display: swap;
}

.font-w-thin {
  font-weight: 100;
}
.font-w-thin-im {
  font-weight: 100 !important;
}
.font-w-light {
  font-weight: 300;
}
.font-w-light-im {
  font-weight: 300 !important;
}
.font-w-regular, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  font-weight: 400;
}
.font-w-regular-im {
  font-weight: 400 !important;
}
.font-w-medium, h6, .h6 {
  font-weight: 500;
}
.font-w-medium-im {
  font-weight: 500 !important;
}
.font-w-bold {
  font-weight: 700;
}
.font-w-bold-im {
  font-weight: 700 !important;
}
.font-w-black {
  font-weight: 900;
}
.font-w-black-im {
  font-weight: 900 !important;
}

h1, .h1 {
  font-size: 70px;
  line-height: 70px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

html body .mat-display-4 {
  font-size: 70px;
  line-height: 70px;
}

h2, .h2 {
  font-size: 56px;
  line-height: 56px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

h3, .h3 {
  font-size: 45px;
  line-height: 48px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

h4, .h4 {
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

h5, .h5 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

h6, .h6 {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

mark {
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  position: relative;
  font-display: swap;
}
.blockquote:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.blockquote.blockquote-reverse {
  padding-right: 1rem;
  padding-left: 0;
  text-align: right;
  border-left: 0;
}
.blockquote.blockquote-reverse:before {
  left: auto;
  right: 0;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

/************************************** LIGHT THEME **************************************/
/************************************** DARK THEME **************************************/
/************************************** COLOR FUNCTION **************************************/
/************************************** COLOR CLASSES **************************************/
/************************************** LIGHT THEME **************************************/
/************************************** DARK THEME **************************************/
/************************************** LIGHT THEME **************************************/
/************************************** DARK THEME **************************************/
/************************************** LIGHT THEME **************************************/
/************************************** DARK THEME **************************************/
/************************************** COLOR FUNCTION **************************************/
/************************************** COLOR CLASSES **************************************/
/************************************** LIGHT THEME **************************************/
/************************************** DARK THEME **************************************/
/************************************** LIGHT THEME **************************************/
/************************************** DARK THEME **************************************/
/************************************** COLOR FUNCTION **************************************/
/************************************** COLOR CLASSES **************************************/
/************************************** LIGHT THEME **************************************/
/************************************** DARK THEME **************************************/
/************************************** COLOR FUNCTION **************************************/
/************************************** COLOR CLASSES **************************************/
/************************************** LIGHT THEME **************************************/
/************************************** DARK THEME **************************************/
/************************************** MATERIAL BASIC STYLES **************************************/
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

/************************************** LIGHT THEME **************************************/
.mat-ripple-element {
  background-color: rgba(33, 33, 33, 0.1);
}

.mat-option {
  color: rgba(33, 33, 33, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(33, 33, 33, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(33, 33, 33, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2196f3;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(33, 33, 33, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(33, 33, 33, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(33, 33, 33, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #2196f3;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(33, 33, 33, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(33, 33, 33, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(33, 33, 33, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #2196f3;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #a8a8a8;
  color: rgba(33, 33, 33, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(33, 33, 33, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #2196f3;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(33, 33, 33, 0.38);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #2196f3;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: #212121;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(33, 33, 33, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(33, 33, 33, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(33, 33, 33, 0.38);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #2196f3;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(33, 33, 33, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(33, 33, 33, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e4e4e4;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e4e4e4;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e4e4e4;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(33, 33, 33, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(33, 33, 33, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(33, 33, 33, 0.38);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e4e4e4;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(33, 33, 33, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(33, 33, 33, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(33, 33, 33, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #2196f3;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(33, 33, 33, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: #212121;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #2196f3;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(33, 33, 33, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(33, 33, 33, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: #212121;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #2196f3;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(33, 33, 33, 0.12);
}

.mat-header-cell {
  color: rgba(33, 33, 33, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(33, 33, 33, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(33, 33, 33, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(33, 33, 33, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(33, 33, 33, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(33, 33, 33, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(33, 33, 33, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(33, 33, 33, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(33, 33, 33, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(33, 33, 33, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(33, 33, 33, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(33, 33, 33, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(33, 150, 243, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #2196f3;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(33, 150, 243, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(33, 150, 243, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(33, 150, 243, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(33, 33, 33, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 64, 129, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 64, 129, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #2196f3;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(33, 33, 33, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(33, 33, 33, 0.87);
}

.mat-divider {
  border-top-color: rgba(33, 33, 33, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(33, 33, 33, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(33, 33, 33, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(33, 33, 33, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(33, 33, 33, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(33, 33, 33, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(33, 33, 33, 0.38);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(33, 33, 33, 0.6);
}

.mat-hint {
  color: rgba(33, 33, 33, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #2196f3;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}

.mat-form-field-ripple {
  background-color: rgba(33, 33, 33, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #2196f3;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #2196f3;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(33, 33, 33, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(33, 33, 33, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(33, 33, 33, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(33, 33, 33, 0.42) 0%, rgba(33, 33, 33, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(33, 33, 33, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(33, 33, 33, 0.42) 0%, rgba(33, 33, 33, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(33, 33, 33, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(33, 33, 33, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(33, 33, 33, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(33, 33, 33, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(33, 33, 33, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(33, 33, 33, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #2196f3;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(33, 33, 33, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(33, 33, 33, 0.06);
}

.mat-icon.mat-primary {
  color: #2196f3;
}
.mat-icon.mat-accent {
  color: #ff4081;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(33, 33, 33, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(33, 33, 33, 0.38);
}

.mat-input-element {
  caret-color: #2196f3;
}
.mat-input-element::placeholder {
  color: rgba(33, 33, 33, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(33, 33, 33, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(33, 33, 33, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(33, 33, 33, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(33, 33, 33, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(33, 33, 33, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(33, 33, 33, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(33, 33, 33, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(33, 33, 33, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(33, 33, 33, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(33, 33, 33, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(33, 33, 33, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(33, 33, 33, 0.54);
  border-right: 2px solid rgba(33, 33, 33, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(33, 33, 33, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(33, 33, 33, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #c4e1f8;
}

.mat-progress-bar-buffer {
  background-color: #c4e1f8;
}

.mat-progress-bar-fill::after {
  background-color: #2196f3;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbccdc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbccdc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #2196f3;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(33, 33, 33, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2196f3;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #2196f3;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(33, 33, 33, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(33, 33, 33, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(33, 33, 33, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: #212121;
}

.mat-select-value {
  color: rgba(33, 33, 33, 0.87);
}

.mat-select-placeholder {
  color: rgba(33, 33, 33, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(33, 33, 33, 0.38);
}

.mat-select-arrow {
  color: rgba(33, 33, 33, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #2196f3;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(33, 33, 33, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(33, 33, 33, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(33, 33, 33, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(33, 33, 33, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(33, 33, 33, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(33, 33, 33, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(33, 33, 33, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #2196f3;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(33, 150, 243, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #2196f3;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #212121;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(33, 33, 33, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #2196f3;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(33, 150, 243, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(33, 33, 33, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(33, 33, 33, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(33, 33, 33, 0.7), rgba(33, 33, 33, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(33, 33, 33, 0.7), rgba(33, 33, 33, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(33, 33, 33, 0.7), rgba(33, 33, 33, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(33, 33, 33, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(33, 33, 33, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #2196f3;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff4081;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(33, 33, 33, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(33, 33, 33, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(33, 33, 33, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #878787;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(33, 33, 33, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(33, 33, 33, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(33, 33, 33, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(33, 33, 33, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(33, 33, 33, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(33, 33, 33, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 222, 251, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #2196f3;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 222, 251, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #2196f3;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff4081;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(33, 33, 33, 0.87);
}
.mat-toolbar.mat-primary {
  background: #2196f3;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(33, 33, 33, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #ff4081;
}

.primary-color-dark {
  color: #1976d2;
}

.primary-color {
  color: #2196f3;
}

.primary-color-light {
  color: #bbdefb;
}

.primary-color-contrast {
  color: white;
}

.accent-color {
  color: #ff4081;
}

.primary-text-color {
  color: rgba(33, 33, 33, 0.87);
}

.secondary-text-color {
  color: rgba(33, 33, 33, 0.54);
}

.warn-color {
  color: #f44336;
}

.icon-color {
  color: rgba(33, 33, 33, 0.54);
}

.white-text-color {
  color: rgba(255, 255, 255, 0.87);
}

.primary-contrast-color {
  color: white;
}

/************************************** BACKGROUND COLOR CLASSES **************************************/
.primary-background-color {
  background-color: #2196f3;
}

.accent-background-color {
  background-color: #ff4081;
}

.warn-background-color {
  background-color: #f44336;
}

.primary-light-background-color {
  background-color: #bbdefb;
}

.background-color-4 {
  background-color: #e0e0e0;
}

mark {
  background-color: #bbdefb;
}

.blockquote:before {
  background-color: #bbdefb;
}

.blockquote-footer {
  color: rgba(33, 33, 33, 0.54);
}

li .item a:-webkit-any-link {
  color: white;
}
li .item a.active {
  color: #2196f3;
}
li .item:hover {
  color: #2196f3;
}
li.open > .item {
  color: #2196f3;
}

.menu-level-0 .children {
  background-color: #2D2D2D;
}

.menu-level-1 .children {
  background-color: #262626;
}

.menu-level-2 .children {
  background-color: #212121;
}

.mat-sidenav-container .mat-sidenav {
  background-color: #FFFFFF;
}
.mat-sidenav-container .mat-sidenav px-gdn-sideitem div.item, .mat-sidenav-container .mat-sidenav px-gdn-sideitem i {
  background-color: #FFFFFF;
  color: #303030;
}

button.mat-simple-snackbar-action {
  color: #ff4081;
}

/************************************** DARK THEME **************************************/
.dark-theme {
  /************************************** BACKGROUND COLOR CLASSES **************************************/
}
.dark-theme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-option {
  color: white;
}
.dark-theme .mat-option:hover:not(.mat-option-disabled), .dark-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.dark-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.dark-theme .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.dark-theme .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2196f3;
}
.dark-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}
.dark-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.dark-theme .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-pseudo-checkbox::after {
  color: white;
}
.dark-theme .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.dark-theme .mat-primary .mat-pseudo-checkbox-checked,
.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #2196f3;
}
.dark-theme .mat-pseudo-checkbox-checked,
.dark-theme .mat-pseudo-checkbox-indeterminate,
.dark-theme .mat-accent .mat-pseudo-checkbox-checked,
.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}
.dark-theme .mat-warn .mat-pseudo-checkbox-checked,
.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.dark-theme .mat-app-background, .dark-theme.mat-app-background {
  background-color: white;
  color: white;
}
.dark-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.dark-theme .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.dark-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.dark-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.dark-theme .mat-badge-content {
  color: white;
  background: #2196f3;
}
.cdk-high-contrast-active .dark-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.dark-theme .mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}
.dark-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.dark-theme .mat-badge-disabled .mat-badge-content {
  background: white;
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.dark-theme .mat-button, .dark-theme .mat-icon-button, .dark-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.dark-theme .mat-button.mat-primary, .dark-theme .mat-icon-button.mat-primary, .dark-theme .mat-stroked-button.mat-primary {
  color: #2196f3;
}
.dark-theme .mat-button.mat-accent, .dark-theme .mat-icon-button.mat-accent, .dark-theme .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.dark-theme .mat-button.mat-warn, .dark-theme .mat-icon-button.mat-warn, .dark-theme .mat-stroked-button.mat-warn {
  color: #f44336;
}
.dark-theme .mat-button.mat-primary.mat-button-disabled, .dark-theme .mat-button.mat-accent.mat-button-disabled, .dark-theme .mat-button.mat-warn.mat-button-disabled, .dark-theme .mat-button.mat-button-disabled.mat-button-disabled, .dark-theme .mat-icon-button.mat-primary.mat-button-disabled, .dark-theme .mat-icon-button.mat-accent.mat-button-disabled, .dark-theme .mat-icon-button.mat-warn.mat-button-disabled, .dark-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .dark-theme .mat-stroked-button.mat-primary.mat-button-disabled, .dark-theme .mat-stroked-button.mat-accent.mat-button-disabled, .dark-theme .mat-stroked-button.mat-warn.mat-button-disabled, .dark-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-button.mat-primary .mat-button-focus-overlay, .dark-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .dark-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #2196f3;
}
.dark-theme .mat-button.mat-accent .mat-button-focus-overlay, .dark-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .dark-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.dark-theme .mat-button.mat-warn .mat-button-focus-overlay, .dark-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .dark-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.dark-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .dark-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .dark-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.dark-theme .mat-button .mat-ripple-element, .dark-theme .mat-icon-button .mat-ripple-element, .dark-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.dark-theme .mat-button-focus-overlay {
  background: white;
}
.dark-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-flat-button, .dark-theme .mat-raised-button, .dark-theme .mat-fab, .dark-theme .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.dark-theme .mat-flat-button.mat-primary, .dark-theme .mat-raised-button.mat-primary, .dark-theme .mat-fab.mat-primary, .dark-theme .mat-mini-fab.mat-primary {
  color: white;
}
.dark-theme .mat-flat-button.mat-accent, .dark-theme .mat-raised-button.mat-accent, .dark-theme .mat-fab.mat-accent, .dark-theme .mat-mini-fab.mat-accent {
  color: white;
}
.dark-theme .mat-flat-button.mat-warn, .dark-theme .mat-raised-button.mat-warn, .dark-theme .mat-fab.mat-warn, .dark-theme .mat-mini-fab.mat-warn {
  color: white;
}
.dark-theme .mat-flat-button.mat-primary.mat-button-disabled, .dark-theme .mat-flat-button.mat-accent.mat-button-disabled, .dark-theme .mat-flat-button.mat-warn.mat-button-disabled, .dark-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .dark-theme .mat-raised-button.mat-primary.mat-button-disabled, .dark-theme .mat-raised-button.mat-accent.mat-button-disabled, .dark-theme .mat-raised-button.mat-warn.mat-button-disabled, .dark-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .dark-theme .mat-fab.mat-primary.mat-button-disabled, .dark-theme .mat-fab.mat-accent.mat-button-disabled, .dark-theme .mat-fab.mat-warn.mat-button-disabled, .dark-theme .mat-fab.mat-button-disabled.mat-button-disabled, .dark-theme .mat-mini-fab.mat-primary.mat-button-disabled, .dark-theme .mat-mini-fab.mat-accent.mat-button-disabled, .dark-theme .mat-mini-fab.mat-warn.mat-button-disabled, .dark-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-flat-button.mat-primary, .dark-theme .mat-raised-button.mat-primary, .dark-theme .mat-fab.mat-primary, .dark-theme .mat-mini-fab.mat-primary {
  background-color: #2196f3;
}
.dark-theme .mat-flat-button.mat-accent, .dark-theme .mat-raised-button.mat-accent, .dark-theme .mat-fab.mat-accent, .dark-theme .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.dark-theme .mat-flat-button.mat-warn, .dark-theme .mat-raised-button.mat-warn, .dark-theme .mat-fab.mat-warn, .dark-theme .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.dark-theme .mat-flat-button.mat-primary.mat-button-disabled, .dark-theme .mat-flat-button.mat-accent.mat-button-disabled, .dark-theme .mat-flat-button.mat-warn.mat-button-disabled, .dark-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .dark-theme .mat-raised-button.mat-primary.mat-button-disabled, .dark-theme .mat-raised-button.mat-accent.mat-button-disabled, .dark-theme .mat-raised-button.mat-warn.mat-button-disabled, .dark-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .dark-theme .mat-fab.mat-primary.mat-button-disabled, .dark-theme .mat-fab.mat-accent.mat-button-disabled, .dark-theme .mat-fab.mat-warn.mat-button-disabled, .dark-theme .mat-fab.mat-button-disabled.mat-button-disabled, .dark-theme .mat-mini-fab.mat-primary.mat-button-disabled, .dark-theme .mat-mini-fab.mat-accent.mat-button-disabled, .dark-theme .mat-mini-fab.mat-warn.mat-button-disabled, .dark-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-flat-button.mat-primary .mat-ripple-element, .dark-theme .mat-raised-button.mat-primary .mat-ripple-element, .dark-theme .mat-fab.mat-primary .mat-ripple-element, .dark-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-flat-button.mat-accent .mat-ripple-element, .dark-theme .mat-raised-button.mat-accent .mat-ripple-element, .dark-theme .mat-fab.mat-accent .mat-ripple-element, .dark-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-flat-button.mat-warn .mat-ripple-element, .dark-theme .mat-raised-button.mat-warn .mat-ripple-element, .dark-theme .mat-fab.mat-warn .mat-ripple-element, .dark-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-stroked-button:not([class*=mat-elevation-z]), .dark-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-fab:not([class*=mat-elevation-z]), .dark-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .dark-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .dark-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.dark-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.dark-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.dark-theme .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.dark-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.dark-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.dark-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.dark-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.dark-theme .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.dark-theme .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.dark-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.dark-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.dark-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.dark-theme .mat-card {
  background: #424242;
  color: white;
}
.dark-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-checkbox-checkmark {
  fill: white;
}
.dark-theme .mat-checkbox-checkmark-path {
  stroke: white !important;
}
.dark-theme .mat-checkbox-mixedmark {
  background-color: white;
}
.dark-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .dark-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #2196f3;
}
.dark-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .dark-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.dark-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .dark-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.dark-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .dark-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.dark-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.dark-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.dark-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.dark-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #2196f3;
}
.dark-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.dark-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.dark-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.dark-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.dark-theme .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.dark-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.dark-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.dark-theme .mat-chip.mat-standard-chip::after {
  background: white;
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #2196f3;
  color: white;
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-table {
  background: #424242;
}
.dark-theme .mat-table thead, .dark-theme .mat-table tbody, .dark-theme .mat-table tfoot,
.dark-theme mat-header-row, .dark-theme mat-row, .dark-theme mat-footer-row,
.dark-theme [mat-header-row], .dark-theme [mat-row], .dark-theme [mat-footer-row],
.dark-theme .mat-table-sticky {
  background: inherit;
}
.dark-theme mat-row, .dark-theme mat-header-row, .dark-theme mat-footer-row,
.dark-theme th.mat-header-cell, .dark-theme td.mat-cell, .dark-theme td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-cell, .dark-theme .mat-footer-cell {
  color: white;
}
.dark-theme .mat-calendar-arrow {
  fill: white;
}
.dark-theme .mat-datepicker-toggle,
.dark-theme .mat-datepicker-content .mat-calendar-next-button,
.dark-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.dark-theme .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-calendar-table-header,
.dark-theme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-calendar-body-cell-content,
.dark-theme .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.dark-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-calendar-body-in-range::before {
  background: rgba(33, 150, 243, 0.2);
}
.dark-theme .mat-calendar-body-comparison-identical,
.dark-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme .mat-calendar-body-comparison-bridge-start::before,
.dark-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-calendar-body-comparison-bridge-end::before,
.dark-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme .mat-calendar-body-selected {
  background-color: #2196f3;
  color: white;
}
.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(33, 150, 243, 0.4);
}
.dark-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.dark-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(33, 150, 243, 0.3);
}
@media (hover: hover) {
  .dark-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(33, 150, 243, 0.3);
  }
}
.dark-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.dark-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.dark-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.dark-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 64, 129, 0.3);
}
@media (hover: hover) {
  .dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 64, 129, 0.3);
  }
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.dark-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.dark-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.dark-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.dark-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-datepicker-toggle-active {
  color: #2196f3;
}
.dark-theme .mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.dark-theme .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.dark-theme .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.dark-theme .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-expansion-panel {
  background: #424242;
  color: white;
}
.dark-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .dark-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .dark-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .dark-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.dark-theme .mat-expansion-panel-header-title {
  color: white;
}
.dark-theme .mat-expansion-panel-header-description,
.dark-theme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.dark-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.dark-theme .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #2196f3;
}
.dark-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.dark-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.dark-theme .mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}
.dark-theme .mat-form-field-ripple {
  background-color: white;
}
.dark-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #2196f3;
}
.dark-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.dark-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.dark-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #2196f3;
}
.dark-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.dark-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.dark-theme .mat-error {
  color: #f44336;
}
.dark-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.dark-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.dark-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.dark-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.dark-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.dark-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #2196f3;
}
.dark-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.dark-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.dark-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.dark-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.dark-theme .mat-icon.mat-primary {
  color: #2196f3;
}
.dark-theme .mat-icon.mat-accent {
  color: #ff4081;
}
.dark-theme .mat-icon.mat-warn {
  color: #f44336;
}
.dark-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-input-element:disabled,
.dark-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-input-element {
  caret-color: #2196f3;
}
.dark-theme .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.dark-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}
.dark-theme .mat-form-field.mat-warn .mat-input-element,
.dark-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.dark-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.dark-theme .mat-list-base .mat-list-item {
  color: white;
}
.dark-theme .mat-list-base .mat-list-option {
  color: white;
}
.dark-theme .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-list-option:hover, .dark-theme .mat-list-option:focus,
.dark-theme .mat-nav-list .mat-list-item:hover,
.dark-theme .mat-nav-list .mat-list-item:focus,
.dark-theme .mat-action-list .mat-list-item:hover,
.dark-theme .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.dark-theme .mat-list-single-selected-option, .dark-theme .mat-list-single-selected-option:hover, .dark-theme .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-menu-panel {
  background: #424242;
}
.dark-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-menu-item {
  background: transparent;
  color: white;
}
.dark-theme .mat-menu-item[disabled],
.dark-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.dark-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-menu-item .mat-icon-no-color,
.dark-theme .mat-menu-submenu-icon {
  color: white;
}
.dark-theme .mat-menu-item:hover:not([disabled]),
.dark-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.dark-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.dark-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.dark-theme .mat-paginator {
  background: #424242;
}
.dark-theme .mat-paginator,
.dark-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-paginator-decrement,
.dark-theme .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.dark-theme .mat-paginator-first,
.dark-theme .mat-paginator-last {
  border-top: 2px solid white;
}
.dark-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.dark-theme .mat-icon-button[disabled] .mat-paginator-increment,
.dark-theme .mat-icon-button[disabled] .mat-paginator-first,
.dark-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-progress-bar-background {
  fill: #c8e5fc;
}
.dark-theme .mat-progress-bar-buffer {
  background-color: #c8e5fc;
}
.dark-theme .mat-progress-bar-fill::after {
  background-color: #2196f3;
}
.dark-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ffcfe0;
}
.dark-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ffcfe0;
}
.dark-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}
.dark-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fcd0cd;
}
.dark-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fcd0cd;
}
.dark-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.dark-theme .mat-progress-spinner circle, .dark-theme .mat-spinner circle {
  stroke: #2196f3;
}
.dark-theme .mat-progress-spinner.mat-accent circle, .dark-theme .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.dark-theme .mat-progress-spinner.mat-warn circle, .dark-theme .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.dark-theme .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2196f3;
}
.dark-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.dark-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .dark-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .dark-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #2196f3;
}
.dark-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.dark-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.dark-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .dark-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .dark-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.dark-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.dark-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.dark-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .dark-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .dark-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.dark-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.dark-theme .mat-select-value {
  color: white;
}
.dark-theme .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-select-panel {
  background: #424242;
}
.dark-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #2196f3;
}
.dark-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.dark-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.dark-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.dark-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-drawer-container {
  background-color: white;
  color: white;
}
.dark-theme .mat-drawer {
  background-color: #424242;
  color: white;
}
.dark-theme .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.dark-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.dark-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.dark-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.dark-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.dark-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.dark-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.dark-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #2196f3;
}
.dark-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(33, 150, 243, 0.54);
}
.dark-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #2196f3;
}
.dark-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.dark-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.dark-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.dark-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.dark-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.dark-theme .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-slider.mat-primary .mat-slider-track-fill,
.dark-theme .mat-slider.mat-primary .mat-slider-thumb,
.dark-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #2196f3;
}
.dark-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.dark-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(33, 150, 243, 0.2);
}
.dark-theme .mat-slider.mat-accent .mat-slider-track-fill,
.dark-theme .mat-slider.mat-accent .mat-slider-thumb,
.dark-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.dark-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.dark-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.dark-theme .mat-slider.mat-warn .mat-slider-track-fill,
.dark-theme .mat-slider.mat-warn .mat-slider-thumb,
.dark-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.dark-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.dark-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.dark-theme .mat-slider:hover .mat-slider-track-background,
.dark-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.dark-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.dark-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.dark-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.dark-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.dark-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.dark-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .dark-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .dark-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.dark-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.dark-theme .mat-step-header.cdk-keyboard-focused, .dark-theme .mat-step-header.cdk-program-focused, .dark-theme .mat-step-header:hover:not([aria-disabled]), .dark-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.dark-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .dark-theme .mat-step-header:hover {
    background: none;
  }
}
.dark-theme .mat-step-header .mat-step-label,
.dark-theme .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.dark-theme .mat-step-header .mat-step-icon-selected,
.dark-theme .mat-step-header .mat-step-icon-state-done,
.dark-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #2196f3;
  color: white;
}
.dark-theme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.dark-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.dark-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.dark-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff4081;
  color: white;
}
.dark-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.dark-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.dark-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.dark-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.dark-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.dark-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.dark-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.dark-theme .mat-stepper-horizontal, .dark-theme .mat-stepper-vertical {
  background-color: #424242;
}
.dark-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-horizontal-stepper-header::before,
.dark-theme .mat-horizontal-stepper-header::after,
.dark-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-sort-header-arrow {
  color: #c6c6c6;
}
.dark-theme .mat-tab-nav-bar,
.dark-theme .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.dark-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.dark-theme .mat-tab-label, .dark-theme .mat-tab-link {
  color: white;
}
.dark-theme .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-tab-header-pagination-chevron {
  border-color: white;
}
.dark-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.dark-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.dark-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 222, 251, 0.3);
}
.dark-theme .mat-tab-group.mat-primary .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #2196f3;
}
.dark-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .dark-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.dark-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.dark-theme .mat-tab-group.mat-accent .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.dark-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .dark-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.dark-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.dark-theme .mat-tab-group.mat-warn .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.dark-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .dark-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.dark-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 222, 251, 0.3);
}
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .dark-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #2196f3;
}
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .dark-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.dark-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .dark-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff4081;
}
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .dark-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.dark-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .dark-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .dark-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.dark-theme .mat-toolbar {
  background: #212121;
  color: white;
}
.dark-theme .mat-toolbar.mat-primary {
  background: #2196f3;
  color: white;
}
.dark-theme .mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.dark-theme .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.dark-theme .mat-toolbar .mat-form-field-underline,
.dark-theme .mat-toolbar .mat-form-field-ripple,
.dark-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.dark-theme .mat-toolbar .mat-form-field-label,
.dark-theme .mat-toolbar .mat-focused .mat-form-field-label,
.dark-theme .mat-toolbar .mat-select-value,
.dark-theme .mat-toolbar .mat-select-arrow,
.dark-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.dark-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.dark-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.dark-theme .mat-tree {
  background: #424242;
}
.dark-theme .mat-tree-node,
.dark-theme .mat-nested-tree-node {
  color: white;
}
.dark-theme .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-simple-snackbar-action {
  color: inherit;
}
.dark-theme .primary-color-dark {
  color: #1976d2;
}
.dark-theme .primary-color {
  color: #2196f3;
}
.dark-theme .primary-color-light {
  color: #bbdefb;
}
.dark-theme .primary-color-contrast {
  color: white;
}
.dark-theme .accent-color {
  color: #ff4081;
}
.dark-theme .primary-text-color {
  color: white;
}
.dark-theme .secondary-text-color {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .warn-color {
  color: #f44336;
}
.dark-theme .icon-color {
  color: white;
}
.dark-theme .white-text-color {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .primary-contrast-color {
  color: white;
}
.dark-theme .primary-background-color {
  background-color: #2196f3;
}
.dark-theme .accent-background-color {
  background-color: #ff4081;
}
.dark-theme .warn-background-color {
  background-color: #f44336;
}
.dark-theme .primary-light-background-color {
  background-color: #bbdefb;
}
.dark-theme .background-color-4 {
  background-color: #212121;
}
.dark-theme mark {
  background-color: #bbdefb;
}
.dark-theme .blockquote:before {
  background-color: #bbdefb;
}
.dark-theme .blockquote-footer {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme li .item a:-webkit-any-link {
  color: white;
}
.dark-theme li .item a.active {
  color: #2196f3;
}
.dark-theme li .item:hover {
  color: #2196f3;
}
.dark-theme li.open > .item {
  color: #2196f3;
}
.dark-theme .menu-level-0 .children {
  background-color: #f5f5f5;
}
.dark-theme .menu-level-1 .children {
  background-color: #eeeeee;
}
.dark-theme .menu-level-2 .children {
  background-color: #e0e0e0;
}
.dark-theme .mat-sidenav-container .mat-sidenav {
  background-color: #fafafa;
}
.dark-theme .mat-sidenav-container .mat-sidenav px-gdn-sideitem div.item, .dark-theme .mat-sidenav-container .mat-sidenav px-gdn-sideitem i {
  background-color: #fafafa;
  color: #303030;
}
.dark-theme .mat-raised-button.mat-accent, .dark-theme .mat-fab.mat-accent, .dark-theme .mat-mini-fab.mat-accent {
  color: #FFFFFF;
}

.grid {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  list-style: none;
  text-align: center;
}

.grid *,
.grid *:after,
.grid *:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Common style */
.grid figure {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  min-width: 320px;
  max-width: 480px;
  max-height: 360px;
  width: 100%;
  height: 100%;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.grid figure .picture {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.grid figure .picture {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.8;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.grid figure h2 span {
  font-weight: 800;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

/* Individual effects */
/*---------------*/
/***** Lily *****/
/*---------------*/
figure.effect-lily .picture {
  max-width: none;
  width: -webkit-calc(100% + 50px);
  width: calc(100% + 50px);
  opacity: 0.7;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-40px, 0, 0);
  transform: translate3d(-40px, 0, 0);
}

figure.effect-lily figcaption {
  text-align: left;
}

figure.effect-lily figcaption > div {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2em;
  width: 100%;
  height: 50%;
}

figure.effect-lily h2,
figure.effect-lily p {
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0);
}

figure.effect-lily h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-lily p {
  color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  -webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
  transition: opacity 0.2s, transform 0.35s;
}

figure.effect-lily:hover .picture,
figure.effect-lily:hover p {
  opacity: 1;
}

figure.effect-lily:hover .picture,
figure.effect-lily:hover h2,
figure.effect-lily:hover p {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-lily:hover p {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
}

/*---------------*/
/***** Sadie *****/
/*---------------*/
figure.effect-sadie figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(top, rgba(72, 76, 97, 0) 0%, rgba(72, 76, 97, 0.8) 75%);
  background: linear-gradient(to bottom, rgba(72, 76, 97, 0) 0%, rgba(72, 76, 97, 0.8) 75%);
  content: "";
  opacity: 0;
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
}

figure.effect-sadie h2 {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  color: #484c61;
  -webkit-transition: -webkit-transform 0.35s, color 0.35s;
  transition: transform 0.35s, color 0.35s;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

figure.effect-sadie figcaption::before,
figure.effect-sadie p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-sadie p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2em;
  width: 100%;
  opacity: 0;
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
}

figure.effect-sadie:hover h2 {
  color: #fff;
  -webkit-transform: translate3d(0, -50%, 0) translate3d(0, -40px, 0);
  transform: translate3d(0, -50%, 0) translate3d(0, -40px, 0);
}

figure.effect-sadie:hover figcaption::before,
figure.effect-sadie:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*---------------*/
/***** Roxy *****/
/*---------------*/
figure.effect-roxy {
  background: -webkit-linear-gradient(45deg, #ff89e9 0%, #05abe0 100%);
  background: linear-gradient(45deg, #ff89e9 0%, #05abe0 100%);
}

figure.effect-roxy .picture {
  max-width: none;
  width: -webkit-calc(100% + 60px);
  width: calc(100% + 60px);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50px, 0, 0);
  transform: translate3d(-50px, 0, 0);
}

figure.effect-roxy figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-20px, 0, 0);
  transform: translate3d(-20px, 0, 0);
}

figure.effect-roxy figcaption {
  padding: 3em;
  text-align: left;
}

figure.effect-roxy h2 {
  padding: 30% 0 10px 0;
}

figure.effect-roxy p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}

figure.effect-roxy:hover .picture {
  opacity: 0.7;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-roxy:hover figcaption::before,
figure.effect-roxy:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*---------------*/
/***** Bubba *****/
/*---------------*/
figure.effect-bubba {
  background: #9e5406;
}

figure.effect-bubba .picture {
  opacity: 0.7;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-bubba:hover .picture {
  opacity: 0.4;
}

figure.effect-bubba figcaption::before,
figure.effect-bubba figcaption::after {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-bubba figcaption::before {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}

figure.effect-bubba figcaption::after {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
}

figure.effect-bubba h2 {
  padding-top: 30%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
}

figure.effect-bubba p {
  padding: 20px 2.5em;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

figure.effect-bubba:hover figcaption::before,
figure.effect-bubba:hover figcaption::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-bubba:hover h2,
figure.effect-bubba:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*---------------*/
/***** Romeo *****/
/*---------------*/
figure.effect-romeo .picture {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(1.4, 1.4, 1);
  transform: scale3d(1.4, 1.4, 1);
}

figure.effect-romeo:hover .picture {
  opacity: 0.6;
  -webkit-transform: scale3d(1, 1, 1);
  transform: translate3d(1, 1, 1);
}

figure.effect-romeo figcaption::before,
figure.effect-romeo figcaption::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 1px;
  background: #fff;
  content: "";
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

figure.effect-romeo:hover figcaption::before {
  opacity: 0.5;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
  transform: translate3d(-50%, -50%, 0) rotate(45deg);
}

figure.effect-romeo:hover figcaption::after {
  opacity: 0.5;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}

figure.effect-romeo h2,
figure.effect-romeo p {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-romeo h2 {
  -webkit-transform: translate3d(0, -50%, 0) translate3d(0, -150%, 0);
  transform: translate3d(0, -50%, 0) translate3d(0, -150%, 0);
}

figure.effect-romeo p {
  padding: 0.25em 2em;
  -webkit-transform: translate3d(0, -50%, 0) translate3d(0, 150%, 0);
  transform: translate3d(0, -50%, 0) translate3d(0, 150%, 0);
}

figure.effect-romeo:hover h2 {
  -webkit-transform: translate3d(0, -50%, 0) translate3d(0, -100%, 0);
  transform: translate3d(0, -50%, 0) translate3d(0, -100%, 0);
}

figure.effect-romeo:hover p {
  -webkit-transform: translate3d(0, -50%, 0) translate3d(0, 100%, 0);
  transform: translate3d(0, -50%, 0) translate3d(0, 100%, 0);
}

/*---------------*/
/***** Layla *****/
/*---------------*/
figure.effect-layla {
  background: #18a367;
}

figure.effect-layla .picture {
  height: 390px;
}

figure.effect-layla figcaption {
  padding: 3em;
}

figure.effect-layla figcaption::before,
figure.effect-layla figcaption::after {
  position: absolute;
  content: "";
  opacity: 0;
}

figure.effect-layla figcaption::before {
  top: 50px;
  right: 30px;
  bottom: 50px;
  left: 30px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

figure.effect-layla figcaption::after {
  top: 30px;
  right: 50px;
  bottom: 30px;
  left: 50px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

figure.effect-layla h2 {
  padding-top: 26%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-layla p {
  padding: 0.5em 2em;
  text-transform: none;
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

figure.effect-layla .picture,
figure.effect-layla h2 {
  -webkit-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0);
}

figure.effect-layla .picture,
figure.effect-layla figcaption::before,
figure.effect-layla figcaption::after,
figure.effect-layla p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-layla:hover .picture {
  opacity: 0.7;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-layla:hover figcaption::before,
figure.effect-layla:hover figcaption::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-layla:hover h2,
figure.effect-layla:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-layla:hover figcaption::after,
figure.effect-layla:hover h2,
figure.effect-layla:hover p,
figure.effect-layla:hover .picture {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

/*---------------*/
/***** Honey *****/
/*---------------*/
figure.effect-honey {
  background: #4a3753;
}

figure.effect-honey .picture {
  opacity: 0.9;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-honey:hover .picture {
  opacity: 0.5;
}

figure.effect-honey figcaption::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: #fff;
  content: "";
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
}

figure.effect-honey h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1em 1.5em;
  width: 100%;
  text-align: left;
  -webkit-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0);
}

figure.effect-honey h2 i {
  font-style: normal;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0);
}

figure.effect-honey figcaption::before,
figure.effect-honey h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-honey:hover figcaption::before,
figure.effect-honey:hover h2,
figure.effect-honey:hover h2 i {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*---------------*/
/***** Oscar *****/
/*---------------*/
figure.effect-oscar {
  background: -webkit-linear-gradient(45deg, #22682a 0%, #9b4a1b 40%, #3a342a 100%);
  background: linear-gradient(45deg, #22682a 0%, #9b4a1b 40%, #3a342a 100%);
}

figure.effect-oscar .picture {
  opacity: 0.9;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-oscar figcaption {
  padding: 3em;
  background-color: rgba(58, 52, 42, 0.7);
  -webkit-transition: background-color 0.35s;
  transition: background-color 0.35s;
}

figure.effect-oscar figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: "";
}

figure.effect-oscar h2 {
  margin: 20% 0 10px 0;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

figure.effect-oscar figcaption::before,
figure.effect-oscar p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0);
  transform: scale(0);
}

figure.effect-oscar:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-oscar:hover figcaption::before,
figure.effect-oscar:hover p {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-oscar:hover figcaption {
  background-color: rgba(58, 52, 42, 0);
}

figure.effect-oscar:hover .picture {
  opacity: 0.4;
}

/*---------------*/
/***** Marley *****/
/*---------------*/
figure.effect-marley figcaption {
  text-align: right;
}

figure.effect-marley h2,
figure.effect-marley p {
  position: absolute;
  right: 30px;
  left: 30px;
  padding: 10px 0;
}

figure.effect-marley p {
  bottom: 30px;
  line-height: 1.5;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

figure.effect-marley h2 {
  top: 30px;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

figure.effect-marley:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-marley h2::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 4px;
  background: #fff;
  content: "";
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0);
}

figure.effect-marley h2::after,
figure.effect-marley p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-marley:hover h2::after,
figure.effect-marley:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*---------------*/
/***** Ruby *****/
/*---------------*/
figure.effect-ruby {
  background-color: #17819c;
}

figure.effect-ruby .picture {
  opacity: 0.7;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

figure.effect-ruby:hover .picture {
  opacity: 0.5;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-ruby h2 {
  margin-top: 20%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

figure.effect-ruby p {
  margin: 1em 0 0;
  padding: 3em;
  border: 1px solid #fff;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0) scale(1.1);
  transform: translate3d(0, 20px, 0) scale(1.1);
}

figure.effect-ruby:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-ruby:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
}

/*---------------*/
/***** Milo *****/
/*---------------*/
figure.effect-milo {
  background: #2e5d5a;
}

figure.effect-milo .picture {
  max-width: none;
  width: -webkit-calc(100% + 60px);
  width: calc(100% + 60px);
  opacity: 1;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-30px, 0, 0) scale(1.12);
  transform: translate3d(-30px, 0, 0) scale(1.12);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-milo:hover .picture {
  opacity: 0.5;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
}

figure.effect-milo h2 {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 1em 1.2em;
}

figure.effect-milo p {
  padding: 0 10px 0 0;
  width: 50%;
  border-right: 1px solid #fff;
  text-align: right;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-40px, 0, 0);
  transform: translate3d(-40px, 0, 0);
}

figure.effect-milo:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*---------------*/
/***** Dexter *****/
/*---------------*/
figure.effect-dexter {
  background: -webkit-linear-gradient(top, #258dc8 0%, #683c13 100%);
  background: linear-gradient(to bottom, #258dc8 0%, #683c13 100%);
}

figure.effect-dexter .picture {
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-dexter:hover .picture {
  opacity: 0.4;
}

figure.effect-dexter figcaption::after {
  position: absolute;
  right: 30px;
  bottom: 30px;
  left: 30px;
  height: -webkit-calc(50% - 30px);
  height: calc(50% - 30px);
  border: 7px solid #fff;
  content: "";
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

figure.effect-dexter:hover figcaption::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-dexter figcaption {
  padding: 3em;
  text-align: left;
}

figure.effect-dexter p {
  position: absolute;
  right: 60px;
  bottom: 60px;
  left: 60px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0);
}

figure.effect-dexter:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*---------------*/
/***** Sarah *****/
/*---------------*/
figure.effect-sarah {
  background: #42b078;
}

figure.effect-sarah .picture {
  max-width: none;
  width: -webkit-calc(100% + 20px);
  width: calc(100% + 20px);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-sarah:hover .picture {
  opacity: 0.4;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-sarah figcaption {
  text-align: left;
}

figure.effect-sarah h2 {
  position: relative;
  overflow: hidden;
  padding: 0.5em 0;
}

figure.effect-sarah h2::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #fff;
  content: "";
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

figure.effect-sarah:hover h2::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-sarah p {
  padding: 1em 0;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

figure.effect-sarah:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*---------------*/
/***** Zoe *****/
/*---------------*/
figure.effect-zoe figcaption {
  top: auto;
  bottom: 0;
  padding: 1em;
  height: 3.75em;
  background: #fff;
  color: #3c4a50;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

figure.effect-zoe h2 {
  float: left;
}

figure.effect-zoe p.icon-links a {
  float: right;
  color: #3c4a50;
  font-size: 1.4em;
}

figure.effect-zoe:hover p.icon-links a:hover,
figure.effect-zoe:hover p.icon-links a:focus {
  color: #252d31;
}

figure.effect-zoe p.description {
  position: absolute;
  bottom: 8em;
  padding: 2em;
  color: #fff;
  text-transform: none;
  font-size: 90%;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* Fix for Chrome 37.0.2062.120 (Mac) */
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
  -webkit-backface-visibility: hidden;
  /* Fix for Chrome 37.0.2062.120 (Mac) */
}

figure.effect-zoe h2,
figure.effect-zoe p.icon-links a {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 200%, 0);
  transform: translate3d(0, 200%, 0);
}

figure.effect-zoe p.icon-links a span::before {
  display: inline-block;
  padding: 8px 10px;
  font-family: "feathericons";
  font-display: "swap";
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eye::before {
  content: "\e000";
}

.icon-paper-clip::before {
  content: "\e001";
}

.icon-heart::before {
  content: "\e024";
}

figure.effect-zoe h2 {
  display: inline-block;
}

figure.effect-zoe:hover p.description {
  opacity: 1;
}

figure.effect-zoe:hover figcaption,
figure.effect-zoe:hover h2,
figure.effect-zoe:hover p.icon-links a {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-zoe:hover h2 {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-zoe:hover p.icon-links a:nth-child(3) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-zoe:hover p.icon-links a:nth-child(2) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

figure.effect-zoe:hover p.icon-links a:first-child {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

/*---------------*/
/***** Chico *****/
/*---------------*/
figure.effect-chico .picture {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.12);
  transform: scale(1.12);
}

figure.effect-chico:hover .picture {
  opacity: 0.5;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-chico figcaption {
  padding: 3em;
}

figure.effect-chico figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: "";
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

figure.effect-chico figcaption::before,
figure.effect-chico p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-chico h2 {
  padding: 20% 0 20px 0;
}

figure.effect-chico p {
  margin: 0 auto;
  max-width: 200px;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

figure.effect-chico:hover figcaption::before,
figure.effect-chico:hover p {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media screen and (max-width: 50em) {
  .grid figure {
    display: inline-block;
    float: none;
    margin: 10px auto;
    width: 100%;
  }
}
html body .mat-card-content, html body .mat-card-header .mat-card-title, html body .mat-card-subtitle {
  font-size: 14px;
  padding: 16px;
}
html body .mat-card {
  padding: 16px;
  margin: 4px;
}
@media screen and (max-width: 557px) {
  html body .mat-card {
    margin: 8px;
  }
}
html body .mat-card .mat-card-actions {
  margin-left: -16px;
  margin-right: -16px;
  padding: 8px 0;
}
html body .mat-card .mat-card-actions:last-child, html body .mat-card .mat-card .mat-card-actions:last-child {
  margin-bottom: -16px;
  margin-left: -16px;
  margin-right: -16px;
  padding: 8px 0;
}

html body .mat-card {
  padding: 8px;
}
html body .mat-card .mat-card-title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
  padding-left: 16px;
  padding-right: 16px;
}
html body .mat-card .mat-card-title:not(:first) {
  margin-top: -8;
  padding-top: 0;
}
html body .mat-card .mat-card-content {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 0px;
}
html body .mat-card .mat-card-content p {
  margin-bottom: 0;
  margin-top: 4px;
  font-size: 12px;
}
html body .mat-card .mat-card-content:first-child {
  margin-top: 0;
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
  .mat-card-footer .center {
    padding: 0 4px 4px 4px !important;
  }
}
@media screen and (min-width: 900px) and (max-width: 999px) {
  .mat-card-footer .center {
    padding: 0 8px 4px 8px !important;
  }
}
.card-frame, .card-frame.mat-card:not([class*=mat-elevation-z]) {
  border: 1px dashed #5a5a5a;
  background: unset;
  box-shadow: unset;
}

html, body {
  margin: 0;
  width: 100%;
  height: 100%;
}

router-outlet ~ * {
  display: block;
  width: 100%;
  height: 100%;
}

.page {
  min-height: calc(100vh - 200px);
}

.page-toolbar {
  transition: none !important;
  box-shadow: none !important;
}
.page-toolbar .mat-toolbar.mat-primary {
  background: linear-gradient(90deg, #217FF3 0%, #0E65D0 100%), #C4C4C4;
}
.page-toolbar .mat-toolbar {
  transition: none !important;
  box-shadow: none !important;
  margin-bottom: 2px solid linear-gradient(90deg, #217FF3 0%, #0E65D0 100%), #C4C4C4;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}

.page-toolbar.custom .mat-toolbar {
  z-index: 20;
}

.page-section {
  padding: 90px 5vw 70px 5vw;
  min-height: calc(100vh - 200px);
}

.page-section-suitability {
  padding: 80px 20px 50px 20px;
  box-sizing: border-box;
}
@media screen and (max-width: 557px) {
  .page-section-suitability {
    padding: 80px 0 0 0;
  }
}
@media screen and (min-width: 558px) and (max-width: 960px) {
  .page-section-suitability {
    padding: 70px 15px 40px 15px;
  }
}
.page-section-suitability.wrapper-box, .page-section-suitability.wrapper-box-desktop {
  height: 100vh;
  padding: 80px 10vw 0 10vw;
}
@media screen and (max-width: 900px) {
  .page-section-suitability.wrapper-box, .page-section-suitability.wrapper-box-desktop {
    padding: 80px 5vw 0 5vw;
  }
}

.page.page-no-footer {
  min-height: 100vh;
}

body * {
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -o-user-select: text;
}

.info-footer {
  background-color: #e6e6e6;
  color: #888888;
  padding: 20px 20px 32px 20px;
}
@media screen and (max-width: 960px) {
  .info-footer {
    padding: 15px 15px 24px 15px;
  }
}

/************************************** SIDE PANEL STYLES **************************************/
.mat-sidenav {
  width: 280px;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.perfect-scrollbar {
  height: auto;
}

.svgicon, .svgicon.mat-icon {
  width: 20px;
  height: 20px;
  padding-top: 8px;
}

.svgicon:not(.color-icon-override), .svgicon.mat-icon:not(.color-icon-override) {
  fill: currentColor;
}
.svgicon:not(.color-icon-override) svg *, .svgicon.mat-icon:not(.color-icon-override) svg * {
  fill: currentColor;
}

.svgicon.fill.mat-icon {
  width: 100%;
  height: 100%;
}

.svgicon.titan.mat-icon {
  width: 256px;
  height: 246px;
}

.svgicon.colossal.mat-icon {
  width: 128px;
  height: 128px;
}

.svgicon.macro.mat-icon {
  width: 64px;
  height: 64px;
}

.svgicon.big.mat-icon {
  width: 24px;
  height: 24px;
}

.svgicon.small.mat-icon {
  width: 16px;
  height: 16px;
  padding-top: 4px;
}

.svgicon.micro.mat-icon {
  width: 12px;
  height: 12px;
  padding-top: 4px;
}

.svgicon.nano.mat-icon {
  width: 10px;
  height: 10px;
  padding-top: 4px;
}

.dark-theme li .item a.active svg path {
  fill: #2196F3;
}

.svgicon.primary-color svg path {
  fill: #2196F3;
}

.svgicon.white svg path {
  fill: white;
}

.svgicon.grey svg path {
  fill: #cacaca;
}

.svgicon.black svg path {
  fill: black;
}

.svgicon.mat-icon.fa {
  height: 48px;
  width: 48px;
  font-size: 22px;
  margin-top: -4px;
  margin-left: 2px;
}

.svgicon.mat-icon.material-icons {
  padding-top: 0;
}

.title-container {
  padding: 16px 8px 0px 8px;
  padding-right: 0;
  max-width: calc(100% - 16px);
}
.title-container .title-color {
  color: #888888;
}
.title-container .title {
  color: #888888;
  padding: 8px 8px 0 0;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 400;
}
.title-container .black {
  color: black;
}
.title-container .title.h1 {
  font-size: 40px;
}
.title-container .title.h2 {
  font-size: 32px;
}
.title-container .title.h3 {
  font-size: 24px;
}
.title-container .title.h4 {
  font-size: 18px;
}
.title-container .title.h5 {
  font-size: 15px;
}
@media screen and (max-width: 599px) {
  .title-container .title {
    line-height: 40px;
  }
  .title-container .title.h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .title-container .title.h2 {
    font-size: 28px;
    line-height: 20px;
  }
  .title-container .title.h3 {
    font-size: 16px;
    line-height: 20px;
  }
  .title-container .title.h4 {
    font-size: 14px;
    line-height: 20px;
  }
  .title-container .title.h5 {
    font-size: 13px;
    line-height: 20px;
  }
}

.upper {
  text-transform: uppercase;
}

.capital {
  text-transform: capitalize;
}

.title-container.padding-fix {
  padding-left: 8px;
  padding-top: 0;
  max-width: calc(100% - 32px);
}
.title-container.padding-fix .title {
  margin: 0;
}

.title-container.no-padding, .title-container.no-padding > * {
  padding: 0;
  margin-bottom: 8px;
}

.title-container app-backlink .voltar {
  margin-left: 0px;
}

.title-container .title-container, .title-container .title-container.padding-fix, .title-container-wrapper .title-container, .title-container-wrapper .title-container.padding-fix {
  max-width: unset;
}

.flex-container.columns {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  place-content: flex-start space-between;
  align-items: flex-start;
}
.flex-container.columns.stretch {
  place-content: stretch space-between;
  align-items: stretch;
}

.flex-container.column {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  max-width: 100%;
  place-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.flex-container.column.space-between {
  place-content: stretch space-between;
  align-items: stretch;
}
.flex-container.column.stretch {
  place-content: stretch flex-start;
  align-items: stretch;
}

.flex-container .columns {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  place-content: flex-start space-between;
  align-items: flex-start;
}
.flex-container .columns.stretch {
  place-content: stretch space-between;
  align-items: stretch;
}
.flex-container .column {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  max-width: 100%;
  place-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.flex-container .column.space-between {
  place-content: stretch space-between;
  align-items: stretch;
}
.flex-container .column.stretch {
  place-content: stretch flex-start;
  align-items: stretch;
}

/************************************** COMMON STYLES **************************************/
.left {
  float: left !important;
}

.right {
  float: right !important;
}

.mat-drawer-content {
  overflow: unset !important;
}

.mat-drawer-container {
  overflow: unset !important;
}

.mat-button.mat-primary:hover, .mat-icon-button.mat-primary:hover, .mat-stroked-button.mat-primary:hover, .mat-raised-button.mat-primary, .mat-toolbar.mat-primary, .primary-gradient {
  background: linear-gradient(135deg, #2196f3 1%, #0969b4 100%);
}

.mat-button.mat-accent:hover, .mat-icon-button.mat-accent:hover, .mat-stroked-button.mat-accent:hover, .mat-raised-button.mat-accent, .accent-gradient {
  background: linear-gradient(135deg, #ff4081 1%, #d92b67 100%);
}

.mat-button.mat-warn:hover, .mat-icon-button.mat-warn:hover, .mat-stroked-button.mat-warn:hover, .mat-raised-button.mat-warn {
  background: linear-gradient(135deg, #f44336 1%, #b02316 100%);
}

.mat-chip.mat-chip-selected.mat-accent {
  color: white;
}

/************************************** INPUT FORM STYLES **************************************/
.input-title {
  margin-bottom: -8px;
}

.mat-focused.no-float .mat-form-field-placeholder {
  display: none !important;
  transform: unset !important;
}

/************************************** TOOLBAR STYLES **************************************/
.mat-toolbar {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-toolbar.mat-primary {
  color: white;
}

/************************************** BUTTON STYLES **************************************/
.mat-button {
  text-transform: uppercase;
}

.mat-raised-button, .mat-fab.mat-accent, .mat-mini-fab {
  color: #FFFFFF !important;
  text-transform: uppercase;
}

.mat-raised-button.small, .mat-buton.small {
  height: 24px;
  vertical-align: middle;
  display: -webkit-box;
  line-height: 0px;
  font-size: 12px;
}

.mat-raised-button.large, .mat-buton.large {
  padding-left: 32px;
  padding-right: 32px;
}

.mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  background: rgba(0, 0, 0, 0.12);
}

.mat-button .mat-button-focus-overlay, .mat-icon-button .mat-button-focus-overlay, .mat-stroked-button .mat-button-focus-overlay {
  background-color: unset !important;
}
.mat-button.mat-primary:hover, .mat-icon-button.mat-primary:hover, .mat-stroked-button.mat-primary:hover {
  color: white;
}
.mat-button.mat-accent:hover, .mat-icon-button.mat-accent:hover, .mat-stroked-button.mat-accent:hover {
  color: white;
}
.mat-button.mat-warn:hover, .mat-icon-button.mat-warn:hover, .mat-stroked-button.mat-warn:hover {
  color: white;
}

/************************************ MAT-DIALOG STYLES ******************************************/
.mat-dialog-container {
  padding: 0 !important;
  border-radius: 8px !important;
}
.mat-dialog-container .mat-dialog-content {
  max-width: 100%;
  margin: 0;
  padding: 24px;
  width: 70%;
  margin: auto;
}

.modal-overlay {
  margin: 0 auto;
  width: 900px !important;
}
.modal-overlay .modal, .modal-overlay .mat-dialog-container {
  max-width: 100%;
  max-height: 600px;
}
.modal-overlay .modal .mat-dialog-content, .modal-overlay .mat-dialog-container .mat-dialog-content {
  max-height: unset;
}
.modal-overlay .modal .mat-toolbar, .modal-overlay .mat-dialog-container .mat-toolbar {
  height: 64px;
  width: 100%;
}
.modal-overlay .modal .mat-toolbar:after, .modal-overlay .mat-dialog-container .mat-toolbar:after {
  margin-top: 64px;
}
.modal-overlay .mat-dialog-container {
  border-radius: 8px !important;
}
@media screen and (max-width: 599px) {
  .modal-overlay {
    max-width: 100vw !important;
    width: 100%;
    margin: auto;
  }
  .modal-overlay .modal, .modal-overlay .mat-dialog-container {
    height: 100%;
    max-height: 100%;
    width: 90%;
    margin: auto;
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .modal-overlay {
    max-width: 80vw;
  }
  .modal-overlay .modal, .modal-overlay .mat-dialog-container {
    max-height: 50vh;
  }
}

@media screen and (max-width: 599px) {
  .onesignal-bell-container-bottom-right {
    bottom: 64px !important;
    right: 14px !important;
    display: none !important;
  }

  #onesignal-bell-container .onesignal-bell-launcher, #onesignal-bell-container .onesignal-bell-launcher-button {
    width: 32px !important;
    height: 32px !important;
    display: none !important;
  }
}
@media screen and (min-width: 600px) {
  .onesignal-bell-container-bottom-right {
    bottom: 4px !important;
    right: calc(100vw - 80px) !important;
  }
  .onesignal-bell-container-bottom-right .onesignal-bell-launcher-dialog, .onesignal-bell-container-bottom-right .onesignal-bell-launcher-dialog:after {
    right: auto !important;
  }
  .onesignal-bell-container-bottom-right .onesignal-bell-launcher-message {
    right: 0 !important;
    left: 70px !important;
    width: max-content !important;
  }
  .onesignal-bell-container-bottom-right .onesignal-bell-launcher-message::after {
    left: -18px !important;
    transform: rotate(180deg) !important;
  }
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

/****************************************** OWL STYLES ******************************************/
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #ff4081 !important;
}

/************************************** ANIMATIONS STYLES **************************************/
.loading-random-messages:after {
  display: inline-block;
  animation: random-messages steps(1, end) 10s infinite;
  content: "";
}

@keyframes random-messages {
  0% {
    content: "Verificando o Tesouro direto";
  }
  10% {
    content: "Acompanhando o CDI";
  }
  20% {
    content: "Organizando investimentos";
  }
  30% {
    content: "Falando com o assessor";
  }
  40% {
    content: "Consultando alguns CDBs";
  }
  50% {
    content: "Trazendo a melhor rentabilidade";
  }
  60% {
    content: "Preparando títulos de investimentos";
  }
  70% {
    content: "Conversando com as corretoras";
  }
  80% {
    content: "Planejando o futuro";
  }
  90% {
    content: "Conectando-se ao mundo dos investimentos";
  }
  100% {
    content: "Alimentando o porco do App";
  }
}
.loading-dotty:after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: "";
}

@keyframes dotty {
  0% {
    content: "   ";
  }
  25% {
    content: ".  ";
  }
  50% {
    content: ".. ";
  }
  75% {
    content: "...";
  }
  100% {
    content: "   ";
  }
}
.fade-in {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#blah {
  background-color: blue;
  color: aqua;
}

div.mat-datepicker-dialog > mat-dialog-container.mat-dialog-container {
  overflow: unset !important;
}

.cc-window.cc-floating {
  max-width: unset !important;
}

.cc-bottom {
  bottom: 0em !important;
}

/*CARROSSEL P2P*/
.swiper-pagination-bullet-active {
  opacity: 1 !important;
  background: #FF4081 !important;
}

.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
  background: rgba(0, 0, 0, 0.55);
  border: 2px solid #FF4081 !important;
}

swiper > .swiper.s-wrapper .swiper-pagination .swiper-pagination-handle .swiper-pagination-bullet.swiper-pagination-bullet-first {
  border: 1px solid #FF4081 !important;
}

swiper > .swiper.s-wrapper .swiper-pagination .swiper-pagination-handle .swiper-pagination-bullet.swiper-pagination-bullet-last {
  border: 1px solid #FF4081 !important;
}

swiper > .swiper.s-wrapper .swiper-wrapper .swiper-slide {
  margin: auto 0 !important;
}

/************************************** DARK MODE **************************************/
.dark-toolbar {
  color: #FFFFFF;
  background-image: linear-gradient(90deg, #303030 0%, #121212 100%) !important;
}

.dark-menu {
  background: #484848;
}
.dark-menu button.mat-menu-item {
  color: #FFFFFF;
}

.dark-primary-color {
  background: #121212 !important;
}

.dark-background {
  background: #121212 !important;
  box-shadow: none !important;
  z-index: 0;
}
.dark-background .blue-banner, .dark-background .pink-back, .dark-background .blue-back {
  background: #121212 !important;
}
.dark-background mat-divider {
  border-top-color: white;
}

.dark-asset-card {
  background: #121212 !important;
  box-shadow: none !important;
  border: 1px solid white;
}

.dark-background-response-calc {
  background: #121212 !important;
  border: 2px solid #FFFFFF;
  border-radius: 9px;
}
.dark-background-response-calc mat-divider {
  background-color: white;
}

.dark-mat-dialog {
  background: #383636 !important;
  background-color: #383636 !important;
  box-shadow: none !important;
  border: none !important;
  width: auto !important;
  margin-bottom: 0 !important;
}

.dark-secondary-color {
  background: #383636 !important;
  background-color: #383636 !important;
  box-shadow: none !important;
  border: none !important;
}
.dark-secondary-color mat-divider {
  border-top-color: white;
}
.dark-secondary-color .option:hover {
  background: #444444 !important;
}
.dark-secondary-color div.item {
  background-color: #383636 !important;
  color: #FFFFFF !important;
}
.dark-secondary-color div.item .icon {
  color: #FFFFFF !important;
}
.dark-secondary-color div.item .text {
  color: #FFFFFF !important;
}
.dark-secondary-color div.item i {
  background-color: #383636 !important;
  color: #FFFFFF !important;
}

.dark-terciary-color {
  background-color: #474747 !important;
  background: #474747 !important;
}
.dark-terciary-color mat-divider {
  border-top-color: white;
}

.dark-message-box {
  background-color: #474747 !important;
  background: #474747 !important;
}
.dark-message-box button[disabled] {
  color: lightslategray !important;
}

.dark-filter {
  background: #121212 !important;
  background-color: #121212 !important;
  box-shadow: none !important;
  border: none !important;
}
@media screen and (max-width: 1280px) {
  .dark-filter {
    background: #383636 !important;
    background-color: #383636 !important;
  }
}

.dark-input {
  background: #474747 !important;
  border: 1px solid #FF4081 !important;
  box-sizing: border-box;
  border-radius: 40px;
  color: white !important;
}

.dark-mat-select .mat-select-value, .dark-mat-select .mat-select-arrow {
  color: white !important;
}

.dark-mat-form-field .mat-form-field-appearance-outline, .dark-mat-form-field .mat-form-field-outline {
  color: #FFFFFF !important;
}
.dark-mat-form-field .mat-radio-label-content {
  color: #FFFFFF !important;
}
.dark-mat-form-field ::placeholder {
  color: #c4c4c4;
  padding-left: -20px;
}
.dark-mat-form-field .mat-input-element {
  color: white;
}
.dark-mat-form-field .mat-select-placeholder {
  color: #c4c4c4;
}
.dark-mat-form-field .mat-select-arrow {
  color: white;
}
.dark-mat-form-field .mat-form-field-label {
  color: white !important;
}
.dark-mat-form-field .mat-select-value, .dark-mat-form-field .mat-select-arrow {
  color: white !important;
}
.dark-mat-form-field .mat-form-field-underline {
  background-color: white;
}
.dark-mat-form-field .mat-form-field-underline::before {
  background-color: white;
}
.dark-mat-form-field .mat-form-field-ripple {
  background-color: #888888 !important;
}
.dark-mat-form-field .mat-input-element:disabled, .dark-mat-form-field .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: #888888 !important;
}
.dark-mat-form-field .mat-input-element:disabled .dm, .dark-mat-form-field .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after .dm {
  color: white !important;
}
.dark-mat-form-field .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  color: #888888 !important;
}
.dark-mat-form-field .mat-hint {
  color: white !important;
}
.dark-mat-form-field .mat-icon-button {
  color: white !important;
}
.dark-mat-form-field .mat-radio-outer-circle {
  border-color: white !important;
}
.dark-mat-form-field .mat-radio-inner-circle {
  background-color: white !important;
}
.dark-mat-form-field .mat-radio-button.mat-accent .mat-radio-inner-circle, .dark-mat-form-field .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .dark-mat-form-field .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .dark-mat-form-field .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: white !important;
}

.dark-mat-form-field.mat-form-field-invalid .mat-form-field-appearance-outline, .dark-mat-form-field.mat-form-field-invalid .mat-form-field-outline {
  color: #f44336 !important;
}
.dark-mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336 !important;
}
.dark-mat-form-field.mat-form-field-invalid .mat-form-field-underline {
  background-color: #f44336 !important;
}
.dark-mat-form-field.mat-form-field-invalid .mat-form-field-underline::before {
  background-color: #f44336 !important;
}

.dark-mat-form-field-with-autocomplete .mat-form-field-appearance-outline, .dark-mat-form-field-with-autocomplete .mat-form-field-outline {
  color: #FFFFFF !important;
}
.dark-mat-form-field-with-autocomplete ::placeholder {
  color: #c4c4c4;
  padding-left: -20px;
}
.dark-mat-form-field-with-autocomplete .mat-input-element {
  color: white;
}
.dark-mat-form-field-with-autocomplete .mat-select-placeholder {
  color: #c4c4c4;
}
.dark-mat-form-field-with-autocomplete .mat-select-arrow {
  color: white;
}
.dark-mat-form-field-with-autocomplete .mat-form-field-label {
  color: white !important;
}
.dark-mat-form-field-with-autocomplete .mat-select-value, .dark-mat-form-field-with-autocomplete .mat-select-arrow {
  color: white !important;
}
.dark-mat-form-field-with-autocomplete .mat-form-field-underline {
  background-color: white !important;
}
.dark-mat-form-field-with-autocomplete .mat-input-element:disabled, .dark-mat-form-field-with-autocomplete .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: #888888 !important;
}
.dark-mat-form-field-with-autocomplete .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  color: #888888 !important;
}
.dark-mat-form-field-with-autocomplete .mat-hint {
  color: white !important;
}
.dark-mat-form-field-with-autocomplete .mat-icon-button {
  color: white !important;
}
.dark-mat-form-field-with-autocomplete .mat-radio-outer-circle {
  border-color: white !important;
}
.dark-mat-form-field-with-autocomplete .mat-radio-inner-circle {
  background-color: white !important;
}
.dark-mat-form-field-with-autocomplete .mat-radio-button.mat-accent .mat-radio-inner-circle, .dark-mat-form-field-with-autocomplete .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .dark-mat-form-field-with-autocomplete .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .dark-mat-form-field-with-autocomplete .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: white !important;
}
.dark-mat-form-field-with-autocomplete .mat-form-field-flex {
  background-color: transparent !important;
  border: 1px solid white;
  box-shadow: none !important;
}
.dark-mat-form-field-with-autocomplete .mat-form-field-flex mat-icon {
  color: white;
}

.light-mat-form-field ::placeholder {
  color: #c4c4c4;
  padding-left: -20px;
}

.dark-primary-font {
  color: #FFFFFF !important;
}
.dark-primary-font .mat-slide-toggle-bar {
  background-color: #888888;
}
.dark-primary-font .mat-checkbox-inner-container {
  color: #FFFFFF !important;
}
.dark-primary-font .mat-checkbox-frame {
  border-color: white;
}

.dark-bolded-font {
  color: #FFFFFF !important;
  font-weight: 600;
}

.dark-robot-card mat-card {
  background: #474747;
}
.dark-robot-card mat-card-title, .dark-robot-card mat-card-subtitle, .dark-robot-card label.label, .dark-robot-card span.value {
  color: white !important;
}
.dark-robot-card div.footer {
  background: #303030 !important;
}
.dark-robot-card div.footer .taxa {
  color: #FFFFFF !important;
}

.dark-robot-card-patrocinado mat-card {
  background: linear-gradient(45deg, #79651c 24%, #323232 70%);
}
.dark-robot-card-patrocinado .patrocinado-text {
  display: block !important;
}
.dark-robot-card-patrocinado mat-card-title, .dark-robot-card-patrocinado mat-card-subtitle, .dark-robot-card-patrocinado label.label, .dark-robot-card-patrocinado span.value {
  color: #FFFFFF !important;
}
.dark-robot-card-patrocinado mat-card-title .patrocinado-text, .dark-robot-card-patrocinado mat-card-subtitle .patrocinado-text, .dark-robot-card-patrocinado label.label .patrocinado-text, .dark-robot-card-patrocinado span.value .patrocinado-text {
  display: block !important;
}
.dark-robot-card-patrocinado div.footer {
  background: #4e4519 !important;
}
.dark-robot-card-patrocinado div.footer .taxa {
  color: #FFFFFF !important;
}

.dark-card {
  background: #474747 !important;
}
.dark-card .mat-card-title, .dark-card .mat-card-subtitle, .dark-card label.label, .dark-card span.value {
  color: #FFFFFF !important;
}
.dark-card div.footer {
  background: #303030 !important;
}
.dark-card div.footer .taxa {
  color: #FFFFFF !important;
}

.dark-card-patrocinado {
  background: linear-gradient(150deg, #424242 13.72%, #2D3A5C 41%, #217FF3 100%);
}
.dark-card-patrocinado .mat-card-title, .dark-card-patrocinado .mat-card-subtitle, .dark-card-patrocinado label.label, .dark-card-patrocinado span.value {
  color: #FFFFFF !important;
}
.dark-card-patrocinado div.footer {
  background-color: #00000044 !important;
}
.dark-card-patrocinado div.footer .taxa, .dark-card-patrocinado div.footer .indicador_percentage {
  color: #FFFFFF !important;
}
.dark-card-patrocinado div.footer .mat-stroked-button {
  color: #FFFFFF;
  border: 1px #ffffff solid;
}

.light-mat-tab-group {
  background-color: #EEEEEE !important;
}
.light-mat-tab-group .mat-tab-label-active {
  color: #2196f3 !important;
}
.light-mat-tab-group .mat-ink-bar {
  background-color: #2196f3 !important;
}

.dark-mat-tab-group {
  background-color: #121212 !important;
  color: white;
}
.dark-mat-tab-group .mat-tab-label-active {
  color: #00FFD2 !important;
}
.dark-mat-tab-group .mat-ink-bar {
  background-color: #00FFD2 !important;
}
.dark-mat-tab-group .mat-tab-label, .dark-mat-tab-group .mat-tab-link {
  color: white;
}
.dark-mat-tab-group .mat-tab-labels {
  border-bottom: 1px solid #FFFFFF;
}
.dark-mat-tab-group .mat-tab-header-pagination-chevron {
  border-color: white;
}
.dark-mat-tab-group .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #888888;
}
.dark-mat-tab-group .mat-expansion-panel {
  background-color: #383636;
}
.dark-mat-tab-group .mat-expansion-panel-header-title {
  color: white !important;
}
.dark-mat-tab-group .mat-expansion-panel-header-description, .dark-mat-tab-group .mat-expansion-indicator::after {
  color: white !important;
}

.dark-calc-mat-tab-group {
  background-color: #383636 !important;
  color: white;
  border-radius: 20px;
}
.dark-calc-mat-tab-group .mat-tab-label, .dark-calc-mat-tab-group .mat-tab-link {
  color: white;
}
.dark-calc-mat-tab-group .mat-tab-label-active {
  color: #00FFD2;
}
.dark-calc-mat-tab-group .mat-ink-bar {
  background-color: #00FFD2 !important;
}
.dark-calc-mat-tab-group .mat-tab-labels {
  border-bottom: 1px solid #FFFFFF;
}
.dark-calc-mat-tab-group .mat-tab-header-pagination-chevron {
  border-color: white;
}
.dark-calc-mat-tab-group .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #888888;
}
.dark-calc-mat-tab-group .mat-expansion-panel {
  background-color: #383636;
}
.dark-calc-mat-tab-group .mat-expansion-panel-header-title {
  color: white !important;
}
.dark-calc-mat-tab-group .mat-expansion-panel-header-description, .dark-calc-mat-tab-group .mat-expansion-indicator::after {
  color: white !important;
}

.dark-assets-mat-tab-group {
  color: white;
  background-color: #474747 !important;
  border-radius: 20px;
}
.dark-assets-mat-tab-group .mat-tab-label-active {
  color: #00FFD2 !important;
}
.dark-assets-mat-tab-group .mat-ink-bar {
  background-color: #00FFD2 !important;
}
.dark-assets-mat-tab-group .mat-tab-label, .dark-assets-mat-tab-group .mat-tab-link {
  color: white;
}
.dark-assets-mat-tab-group .mat-tab-labels {
  border-bottom: 1px solid #FFFFFF;
}
.dark-assets-mat-tab-group .mat-tab-header-pagination-chevron {
  border-color: white;
}
.dark-assets-mat-tab-group .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #888888;
}
.dark-assets-mat-tab-group .mat-expansion-panel {
  background-color: #383636;
}
.dark-assets-mat-tab-group .mat-expansion-panel-header-title {
  color: white !important;
}
.dark-assets-mat-tab-group .mat-expansion-panel-header-description, .dark-assets-mat-tab-group .mat-expansion-indicator::after {
  color: white !important;
}

.dark-paginator {
  background: #121212 !important;
  box-shadow: none !important;
}
.dark-paginator .mat-button.mat-primary[disabled], .dark-paginator .mat-button.mat-accent[disabled], .dark-paginator .mat-button.mat-warn[disabled], .dark-paginator .mat-button[disabled][disabled], .dark-paginator .mat-icon-button.mat-primary[disabled], .dark-paginator .mat-icon-button.mat-accent[disabled], .dark-paginator .mat-icon-button.mat-warn[disabled], .dark-paginator .mat-icon-button[disabled][disabled], .dark-paginator .mat-stroked-button.mat-primary[disabled], .dark-paginator .mat-stroked-button.mat-accent[disabled], .dark-paginator .mat-stroked-button.mat-warn[disabled], .dark-paginator .mat-stroked-button[disabled][disabled] {
  color: #888888;
}
.dark-paginator .mat-button, .dark-paginator .mat-icon-button, .dark-paginator .mat-stroked-button {
  color: white;
}
.dark-paginator .mat-paginator-page-size-label {
  color: white;
}
.dark-paginator .mat-paginator, .dark-paginator .mat-paginator-page-size .mat-select-trigger {
  color: white;
}
.dark-paginator .mat-form-field-outline {
  background: #474747;
  border: 1px #FFFFFF solid;
  border-radius: 8px;
  color: #FFFFFF !important;
}
.dark-paginator ::placeholder {
  color: #323232;
  padding-left: -20px;
}
.dark-paginator .mat-input-element {
  color: white;
}
.dark-paginator .mat-select-placeholder {
  color: #c4c4c4;
}
.dark-paginator .mat-select-arrow {
  color: white;
}
.dark-paginator .mat-form-field-label {
  color: white;
}
.dark-paginator .mat-select-value, .dark-paginator .mat-select-arrow {
  color: white !important;
}
.dark-paginator .mat-form-field-underline {
  background-color: white !important;
}
.dark-paginator .mat-paginator-range-label {
  color: white !important;
}

.dark-fundos-paginator {
  background: #383636 !important;
  box-shadow: none !important;
}
.dark-fundos-paginator .mat-button.mat-primary[disabled], .dark-fundos-paginator .mat-button.mat-accent[disabled], .dark-fundos-paginator .mat-button.mat-warn[disabled], .dark-fundos-paginator .mat-button[disabled][disabled], .dark-fundos-paginator .mat-icon-button.mat-primary[disabled], .dark-fundos-paginator .mat-icon-button.mat-accent[disabled], .dark-fundos-paginator .mat-icon-button.mat-warn[disabled], .dark-fundos-paginator .mat-icon-button[disabled][disabled], .dark-fundos-paginator .mat-stroked-button.mat-primary[disabled], .dark-fundos-paginator .mat-stroked-button.mat-accent[disabled], .dark-fundos-paginator .mat-stroked-button.mat-warn[disabled], .dark-fundos-paginator .mat-stroked-button[disabled][disabled] {
  color: #888888;
}
.dark-fundos-paginator .mat-button, .dark-fundos-paginator .mat-icon-button, .dark-fundos-paginator .mat-stroked-button {
  color: white;
}
.dark-fundos-paginator .mat-paginator-page-size-label {
  color: white;
}
.dark-fundos-paginator .mat-paginator, .dark-fundos-paginator .mat-paginator-page-size .mat-select-trigger {
  color: white;
}
.dark-fundos-paginator .mat-form-field-outline {
  background: #474747;
  border: 1px #FFFFFF solid;
  border-radius: 8px;
  color: #FFFFFF !important;
}
.dark-fundos-paginator ::placeholder {
  color: #c4c4c4;
  padding-left: -20px;
}
.dark-fundos-paginator .mat-input-element {
  color: white;
}
.dark-fundos-paginator .mat-select-placeholder {
  color: #c4c4c4;
}
.dark-fundos-paginator .mat-select-arrow {
  color: white;
}
.dark-fundos-paginator .mat-form-field-label {
  color: white;
}
.dark-fundos-paginator .mat-select-value, .dark-fundos-paginator .mat-select-arrow {
  color: white !important;
}
.dark-fundos-paginator .mat-form-field-underline {
  background-color: white !important;
}
.dark-fundos-paginator .mat-paginator-range-label {
  color: white !important;
}

.dark-article-toolbar button:not(.mat-primary) {
  color: white;
}

.dark-chat-messages .chat-message.right .chat-bubble {
  background-color: #121212 !important;
}
.dark-chat-messages .chat-message.right .chat-bubble .message {
  color: #ffffff;
}
.dark-chat-messages .chat-message.right .chat-bubble .message-detail .username {
  color: #ffffff;
}
.dark-chat-messages .chat-message.right .chat-bubble:before {
  background-color: #121212 !important;
}
.dark-chat-messages .chat-message.left .chat-bubble {
  background-color: #1964a0 !important;
}
.dark-chat-messages .chat-message.left .chat-bubble .message {
  color: #ffffff;
}
.dark-chat-messages .chat-message.left .chat-bubble .message-detail .username {
  color: #ffffff;
}
.dark-chat-messages .chat-message.left .chat-bubble:before {
  background-color: #1964a0 !important;
}

.dark-table {
  background: transparent;
}
.dark-table th {
  text-align: center;
  font-weight: 700;
  color: #FFFFFF !important;
  background: #383636 !important;
}
.dark-table tr:nth-child(odd) {
  background: #484848 !important;
}
.dark-table tr:nth-child(even) {
  background: #323232 !important;
}
.dark-table .row:nth-child(odd) {
  background: #383636 !important;
}
.dark-table .row:nth-child(even) {
  background: #323232 !important;
}

.dark-suitability .conversational-form {
  background: #383636;
}
.dark-suitability ::before {
  background: linear-gradient(to bottom, #323232 0, rgba(255, 255, 255, 0) 100%) !important;
}

.dark-datepicker {
  background: #121212 !important;
  color: white !important;
}
.dark-datepicker .mat-calendar-arrow {
  border-top-color: white !important;
}
.dark-datepicker .mat-datepicker-content {
  color: white !important;
}
.dark-datepicker .mat-calendar-previous-button {
  color: white;
}
.dark-datepicker .mat-calendar-previous-button[disabled] {
  color: gray;
}
.dark-datepicker .mat-calendar-next-button {
  color: white;
}
.dark-datepicker .mat-calendar-next-button[disabled] {
  color: gray;
}
.dark-datepicker .mat-calendar-table-header {
  color: white;
}
.dark-datepicker .mat-calendar-table-header-divider::after {
  background: white;
}
.dark-datepicker .mat-calendar-body-label {
  color: white;
}
.dark-datepicker .mat-calendar-body-cell-content {
  color: white;
}
.dark-datepicker :disabled {
  color: gray;
}
.dark-datepicker .mat-calendar-body-selected {
  background-color: #2D3A5C;
}
.dark-datepicker .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: gray;
}
.dark-datepicker mat-form-field.break-border-top-mat-form-field-infix .mat-form-field-infix {
  border-top: 0.34375em solid transparent;
}

.dark-mat-card {
  background: transparent;
}

.warning-products-bottom {
  padding: 10px 32px;
  text-align: justify;
  background-color: #DADADA;
}
@media screen and (max-width: 500px) {
  .warning-products-bottom {
    padding: 10px 18px;
  }
}
.warning-products-bottom span {
  font-family: Raleway;
  font-size: 12px;
  line-height: 130%;
}
@media screen and (max-width: 500px) {
  .warning-products-bottom span {
    font-size: 10px;
  }
}