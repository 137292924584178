@use '@angular/material' as mat;
@import '~normalize.css/normalize.css';
// @import "~dragula/dist/dragula.css";
// @import "~quill/dist/quill.snow.css";
// @import "~angular-tree-component/dist/angular-tree-component.css";
@import 'typography';
@import 'theme/theme';
@import 'hovers/hovers';
@import 'cards';
@import 'frame';
@import 'page';
@import 'svgicon';
@import 'title-container';
@import 'flex';

/************************************** COMMON STYLES **************************************/
.left {
  float: left!important;
}

.right {
  float: right!important;
}

.mat-drawer-content {
  overflow: unset!important;
}
.mat-drawer-container {
  overflow: unset!important;
}

%primary-gradient {
  background: linear-gradient(135deg, #2196f3 1%,#0969b4 100%);
}

%accent-gradient {
  background: linear-gradient(135deg, #ff4081 1%,#d92b67 100%);
}

%warn-gradient {
  background: linear-gradient(135deg, #f44336 1%,#b02316 100%);
}

.primary-gradient {
  @extend %primary-gradient;
}

.accent-gradient {
  @extend %accent-gradient;
}

.mat-chip.mat-chip-selected.mat-accent {
  color: white;
}

/************************************** INPUT FORM STYLES **************************************/
.input-title {
  margin-bottom: -8px;
}

.mat-focused.no-float {
  .mat-form-field-placeholder {
    display: none!important;
    transform: unset!important;
  }
}

/************************************** TOOLBAR STYLES **************************************/
.mat-toolbar {
  @include mat.elevation(4);
}

.mat-toolbar.mat-primary {
  // background: #2196F3;
  @extend %primary-gradient;
  color: white;
}

/************************************** BUTTON STYLES **************************************/
.mat-button {
  text-transform: uppercase;
}
.mat-raised-button, .mat-fab.mat-accent, .mat-mini-fab {
  color: #FFFFFF!important;
  text-transform: uppercase;
}

.mat-raised-button.small, .mat-buton.small {
  height: 24px;
  vertical-align: middle;
  display: -webkit-box;
  line-height: 0px;
  font-size: 12px;
}

.mat-raised-button.large, .mat-buton.large {
  padding-left: 32px; 
  padding-right: 32px;
}

.mat-raised-button.mat-primary {
  @extend %primary-gradient;
}

.mat-raised-button.mat-accent {
  @extend %accent-gradient;
}

.mat-raised-button.mat-warn {
  @extend %warn-gradient;
}

.mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  background: rgba(0, 0, 0, 0.12);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  .mat-button-focus-overlay {
    background-color: unset!important;
  }
  // &.framed-button {
  //   border-color: currentColor;
  //   border-style: solid;
  //   border-spacing: 1px;
  //   border-radius: 4px;
  //   &:hover {
  //     border: unset;
  //     margin-top: 2.4px;
  //     margin-bottom: 2.4px;
  //   }
  // }
  &.mat-primary:hover {
    color: white;
    // background-color: #2196f3!important;
    @extend %primary-gradient;
  }
  &.mat-accent:hover {
    color: white;
    // background-color: #ff4081!important;
    @extend %accent-gradient;
  }
  &.mat-warn:hover {
    color: white;
    // background-color: #f44336!important;
    @extend %warn-gradient;
  }
}

/************************************ MAT-DIALOG STYLES ******************************************/

.mat-dialog-container {
  padding: 0!important;
  border-radius: 8px !important;
  .mat-dialog-content {
    max-width: 100%;
    margin: 0;
    padding: 24px;
    width:70%;
    margin: auto;
  }
}

.modal-overlay {
  // max-width: 100vw;
  // max-height: 100vh;
  // width: 100%;
    margin: 0 auto;
    width: 900px !important;

  .modal, .mat-dialog-container {
    max-width: 100%;
    max-height: 600px;
    .mat-dialog-content {
      max-height: unset;
    }
    .mat-toolbar {
      height: 64px;
      width: 100%;
      // position: sticky;
      // top: 0;
      // z-index: 100;
    }
    .mat-toolbar:after {
      margin-top: 64px;
    }
  }
  .mat-dialog-container{
    border-radius: 8px !important;
  }
  // Faz com que o modal fique tela cheia no mobile
  @media screen and (max-width: 599px) {
    max-width: 100vw !important;
    // min-width: 100vw;
    width: 100%;
    margin: auto;
    .modal, .mat-dialog-container {
      // max-width: 100vw;
      // min-width: 100vw;
      // max-height: calc(100vh - 54px);
      // min-height: calc(100vh - 54px);
      height: 100%;
      max-height: 100%;
      width: 90%;
      margin: auto;
      // min-height: 100%;
      // margin-top: 30px;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 959px) {
    max-width: 80vw;
    .modal, .mat-dialog-container {
      // max-width: 80vw;
      max-height: 50vh;
    } 
  }
}

// @media screen and (max-width: 599px) {
//   .modal-overlay {
//     max-width: 70vw;
//     // min-width: 100vw;
//     // max-height: 50vh;
//     // min-height: 100vh;
//     // max-height: 100%;
//     // min-height: 100%;
//     // height: 100%;
//   } 
// }

@media screen and (max-width: 599px) {
  .onesignal-bell-container-bottom-right {
    bottom: 64px!important;
    right: 14px!important;
    display: none !important;
  }
  #onesignal-bell-container .onesignal-bell-launcher, #onesignal-bell-container .onesignal-bell-launcher-button {
    width: 32px!important;
    height: 32px!important;
    display: none !important;
  }
}

.onesignal-bell-container-bottom-right {
  @media screen and (min-width: 600px) {
    bottom: 4px !important;
    right: calc(100vw - 80px) !important;

    .onesignal-bell-launcher-dialog, .onesignal-bell-launcher-dialog:after{
      right: auto !important;
    }

    .onesignal-bell-launcher-message{
      right: 0 !important;
      left: 70px !important;
      width: max-content !important;
    }

    .onesignal-bell-launcher-message::after{
      left: -18px !important;
      transform: rotate(180deg) !important;
    }
  }
}

// #onesignal-bell-container.onesignal-reset{
//   right: 0!important;
// }

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

/****************************************** OWL STYLES ******************************************/
.owl-theme .owl-dots .owl-dot.active, .owl-theme .owl-dots .owl-dot:hover {
  span {
    background: #ff4081!important;
  }
}


/************************************** ANIMATIONS STYLES **************************************/
.loading-random-messages:after {
  display: inline-block;
  animation: random-messages steps(1,end) 10s infinite;
  content: '';
}

@keyframes random-messages {
  0%   { content: 'Verificando o Tesouro direto'; }
  10%  { content: 'Acompanhando o CDI'; }
  20%  { content: 'Organizando investimentos'; }
  30%  { content: 'Falando com o assessor'; }
  40%  { content: 'Consultando alguns CDBs'; }
  50%  { content: 'Trazendo a melhor rentabilidade'; }
  60%  { content: 'Preparando títulos de investimentos'; }
  70%  { content: 'Conversando com as corretoras'; }
  80%  { content: 'Planejando o futuro'; }
  90%  { content: 'Conectando-se ao mundo dos investimentos'; }
  100% { content: 'Alimentando o porco do App'; }
}

.loading-dotty:after {
  display: inline-block;
  animation: dotty steps(1,end) 1s infinite;
  content: '';
}

@keyframes dotty {
  0%   { content: '\00a0\00a0\00a0'; }
  25%  { content: '.\00a0\00a0'; }
  50%  { content: '..\00a0'; }
  75%  { content: '...'; }
  100% { content: '\00a0\00a0\00a0'; }
}

.fade-in {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

@keyframes fadeIn { from { opacity:0; } to { opacity:1; }}

// ::-webkit-scrollbar {
//   width: 16px;
//   height: 2em
// }
// ::-webkit-scrollbar-button {
//     background: #ccc
// }
// ::-webkit-scrollbar-track-piece {
//     background: #888
// }
// ::-webkit-scrollbar-thumb {
//     background: #eee
// }​
#blah {
  background-color: blue;
  color: aqua;
}

div.mat-datepicker-dialog > mat-dialog-container.mat-dialog-container {
	overflow: unset !important;
}

.cc-window.cc-floating {
  max-width: unset !important;
}

.cc-bottom {
  bottom: 0em !important;
}

/*CARROSSEL P2P*/

.swiper-pagination-bullet-active {
  opacity: 1 !important;
  background: #FF4081 !important;
}

.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
  background: rgba(0, 0, 0, 0.55);
  border: 2px solid #FF4081 !important;
}

swiper > .swiper.s-wrapper .swiper-pagination .swiper-pagination-handle 
.swiper-pagination-bullet.swiper-pagination-bullet-first {
  border: 1px solid #FF4081 !important;
}

swiper > .swiper.s-wrapper .swiper-pagination .swiper-pagination-handle 
.swiper-pagination-bullet.swiper-pagination-bullet-last {
  border: 1px solid #FF4081 !important;
}

swiper > .swiper.s-wrapper .swiper-wrapper .swiper-slide {
  margin: auto 0 !important;
}

/************************************** DARK MODE **************************************/

.dark-toolbar{
  color: #FFFFFF;
  background-image: linear-gradient(90deg, #303030 0%, #121212 100%) !important;
}

.dark-menu{
  background: #484848;
  button.mat-menu-item{
    color: #FFFFFF;
  }
}

.dark-primary-color{
  background: #121212 !important;
}

.dark-background{
  background: #121212 !important;
  box-shadow: none !important;
  z-index: 0;

  .blue-banner, .pink-back, .blue-back{
    background: #121212 !important;
  }

  mat-divider{
    border-top-color: white
  }
}

.dark-asset-card{
  background: #121212 !important;
  box-shadow: none !important;
  border: 1px solid white;
}

.dark-background-response-calc{
  background: #121212 !important;
  border: 2px solid #FFFFFF;
  border-radius: 9px;

  mat-divider{
    background-color: white;
  }
}

.dark-mat-dialog{
  background: #383636 !important;
  background-color: #383636 !important;
  box-shadow: none !important;
  border: none !important;
  width: auto !important;
  margin-bottom: 0 !important;
}

.dark-secondary-color{
  background: #383636 !important;
  background-color: #383636 !important;
  box-shadow: none !important;
  border: none !important;
  
  mat-divider{
    border-top-color: white
  }

  .option:hover{
    background: #444444 !important;
  }
  div.item{
    background-color: #383636 !important;
    color: #FFFFFF !important;
    .icon{
      color: #FFFFFF !important;

    }
    .text{
      color: #FFFFFF !important;

    }

    i{
      background-color: #383636 !important;
      color: #FFFFFF !important;
    }
  }
}

.dark-terciary-color{
  background-color: #474747 !important;
  background: #474747 !important;

  mat-divider{
    border-top-color: white
  }
}

.dark-message-box{
  background-color: #474747 !important;
  background: #474747 !important;

  button[disabled]{
    color: lightslategray !important;
  }
}

.dark-filter{
  background: #121212 !important;
  background-color: #121212 !important;
  box-shadow: none !important;
  border: none !important;

  @media screen and (max-width: 1280px) {
    background: #383636 !important;
    background-color: #383636 !important;
  }
}

.dark-input{
  background: #474747 !important;
  border: 1px solid #FF4081 !important;
  box-sizing: border-box;
  border-radius: 40px;
  color: white !important;
}

.dark-mat-select{
  .mat-select-value, .mat-select-arrow{
    color: white !important;
  }
}

.dark-mat-form-field{

  .mat-form-field-appearance-outline, .mat-form-field-outline{
    color: #FFFFFF !important;
  }

  .mat-radio-label-content{
    color: #FFFFFF !important;
  }

  ::placeholder{
    color: #c4c4c4;
    padding-left: -20px;
  }

  .mat-input-element{
    color: white;
  }

  .mat-select-placeholder{
    color: #c4c4c4;
  }

  .mat-select-arrow{
    color: white;
  }

  .mat-form-field-label{
    color: white !important;
  }

  .mat-select-value, .mat-select-arrow{
    color: white !important;
  }

  .mat-form-field-underline {
    background-color: white;
  }

  .mat-form-field-underline::before {
    background-color: white;
  }

  .mat-form-field-ripple{
    background-color: #888888 !important;
  }

  .mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after{
    color: #888888 !important;

    .dm{
      color: white !important;
    }
  }

  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline{
    color: #888888 !important;
  }

  .mat-hint{
    color: white !important;
  }

  .mat-icon-button{
    color: white !important;
  }

  .mat-radio-outer-circle{
    border-color: white !important;
  }

  .mat-radio-inner-circle{
    background-color: white !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
    background-color: white !important;
  }
}

.dark-mat-form-field.mat-form-field-invalid{

  .mat-form-field-appearance-outline, .mat-form-field-outline{
    color: #f44336 !important;
  }

  .mat-form-field-label{
    color: #f44336 !important;
  }

  .mat-form-field-underline {
    background-color: #f44336 !important;
  }

  .mat-form-field-underline::before {
    background-color: #f44336 !important;
  }
}

.dark-mat-form-field-with-autocomplete{

  .mat-form-field-appearance-outline, .mat-form-field-outline{
    color: #FFFFFF !important;
  }

  ::placeholder{
    color: #c4c4c4;
    padding-left: -20px;
  }

  .mat-input-element{
    color: white;
  }

  .mat-select-placeholder{
    color: #c4c4c4;
  }

  .mat-select-arrow{
    color: white;
  }

  .mat-form-field-label{
    color: white !important;
  }

  .mat-select-value, .mat-select-arrow{
    color: white !important;
  }

  .mat-form-field-underline {
    background-color: white !important;
  }

  .mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after{
    color: #888888 !important;
  }

  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline{
    color: #888888 !important;
  }

  .mat-hint{
    color: white !important;
  }

  .mat-icon-button{
    color: white !important;
  }

  .mat-radio-outer-circle{
    border-color: white !important;
  }

  .mat-radio-inner-circle{
    background-color: white !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
    background-color: white !important;
  }
  .mat-form-field-flex{
    background-color: transparent !important;
    border: 1px solid white;
    box-shadow: none !important;
  
    mat-icon{
      color: white;
    }
  }
}

.light-mat-form-field{
  ::placeholder{
    color: #c4c4c4;
    padding-left: -20px;
  }
}

.dark-primary-font{
  color: #FFFFFF !important;
  .mat-slide-toggle-bar {
    background-color: #888888;
  }
  .mat-checkbox-inner-container{
    color: #FFFFFF !important; //terminar mat-checkbox
  }
  .mat-checkbox-frame{
    border-color: white;
  }
}

.dark-bolded-font{
  color: #FFFFFF !important;
  font-weight: 600;
}

.dark-robot-card{
  mat-card{
    background:  #474747;
  }
  mat-card-title, mat-card-subtitle, label.label, span.value{
    color: white !important;
  }
  div.footer{
    background: #303030 !important;    
    .taxa{
      color: #FFFFFF !important;
    }
  }
}

.dark-robot-card-patrocinado{
  mat-card{
    background: linear-gradient(45deg, #79651c 24%, #323232 70%);
  }
  .patrocinado-text {
    display: block !important;
  }
  mat-card-title, mat-card-subtitle, label.label, span.value{
    color: #FFFFFF !important;
    .patrocinado-text {
      display: block !important;
    }
  }
  div.footer{
    background: #4e4519 !important;
    .taxa{
      color: #FFFFFF !important;
    }
  }
}

.dark-card{
  background: #474747 !important;
  .mat-card-title, .mat-card-subtitle, label.label, span.value{
    color: #FFFFFF !important;
  }
  div.footer{
    background: #303030 !important;    
    .taxa{
      color: #FFFFFF !important;
    }
  }
}

.dark-card-patrocinado{
  background: linear-gradient(150deg, #424242 13.72%, #2D3A5C 41%, #217FF3 100%);
  .mat-card-title, .mat-card-subtitle, label.label, span.value{
    color: #FFFFFF !important;
  }
  div.footer{
    background-color: #00000044 !important;
    .taxa, .indicador_percentage{
      color: #FFFFFF !important;
    }

    .mat-stroked-button{
      color: #FFFFFF;
      border: 1px #ffffff solid;
    }
  }
}

.light-mat-tab-group{
  background-color: #EEEEEE !important;
  
  .mat-tab-label-active{
    color: #2196f3 !important;
  }
  .mat-ink-bar{
    background-color: #2196f3 !important;
  }
}

.dark-mat-tab-group{
  background-color: #121212 !important;
  color: white;
  
  .mat-tab-label-active{
    color: #00FFD2 !important;
  }
  .mat-ink-bar{
    background-color: #00FFD2 !important;
  }
  .mat-tab-label, .mat-tab-link{
    color: white;
  }
  .mat-tab-labels{
    border-bottom: 1px solid #FFFFFF;
  }
  .mat-tab-header-pagination-chevron{
    border-color: white;
  }
  .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
    border-color: #888888;
  }

  .mat-expansion-panel{
    background-color: #383636;
  }

  .mat-expansion-panel-header-title{
    color: white !important;
  }

  .mat-expansion-panel-header-description, .mat-expansion-indicator::after{
    color: white !important;
  }
}

.dark-calc-mat-tab-group{
  background-color: #383636 !important;
  color: white;
  border-radius: 20px;

  .mat-tab-label, .mat-tab-link{
    color: white;
  }
  .mat-tab-label-active{
    color: #00FFD2;
  }
  .mat-ink-bar{
    background-color: #00FFD2 !important;
  }
  .mat-tab-labels{
    border-bottom: 1px solid #FFFFFF;
  }
  .mat-tab-header-pagination-chevron{
    border-color: white;
  }
  .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
    border-color: #888888;
  }

  .mat-expansion-panel{
    background-color: #383636;
  }

  .mat-expansion-panel-header-title{
    color: white !important;
  }

  .mat-expansion-panel-header-description, .mat-expansion-indicator::after{
    color: white !important;
  }
}

.dark-assets-mat-tab-group{
  color: white;
  background-color: #474747 !important;
  border-radius: 20px;
  
  .mat-tab-label-active{
    color: #00FFD2 !important;
  }
  .mat-ink-bar{
    background-color: #00FFD2 !important;
  }
  .mat-tab-label, .mat-tab-link{
    color: white;
  }
  .mat-tab-labels{
    border-bottom: 1px solid #FFFFFF;
  }
  .mat-tab-header-pagination-chevron{
    border-color: white;
  }
  .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
    border-color: #888888;
  }

  .mat-expansion-panel{
    background-color: #383636;
  }

  .mat-expansion-panel-header-title{
    color: white !important;
  }

  .mat-expansion-panel-header-description, .mat-expansion-indicator::after{
    color: white !important;
  }
}

.dark-paginator{
  background: #121212 !important;
  box-shadow: none !important;

  .mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled]{
    color: #888888;
  }

  .mat-button, .mat-icon-button, .mat-stroked-button{
    color: white;
  }

  .mat-paginator-page-size-label{
    color: white;
  }

  .mat-paginator, .mat-paginator-page-size .mat-select-trigger{
    color: white;
  }

  .mat-form-field-outline{
    background: #474747;
    border: 1px #FFFFFF solid;
    border-radius: 8px;
    color: #FFFFFF !important;
  }

  ::placeholder{
    color: #323232;
    padding-left: -20px;
  }

  .mat-input-element{
    color: white;
  }

  .mat-select-placeholder{
    color: #c4c4c4;
  }

  .mat-select-arrow{
    color: white;
  }

  .mat-form-field-label{
    color: white;
  }

  .mat-select-value, .mat-select-arrow{
    color: white !important;
  }

  .mat-form-field-underline {
    background-color: white !important;
  }

  .mat-paginator-range-label{
    color: white !important;
  }
}

.dark-fundos-paginator{
  background:#383636 !important;
  box-shadow: none !important;

  .mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled]{
    color: #888888;
  }

  .mat-button, .mat-icon-button, .mat-stroked-button{
    color: white;
  }

  .mat-paginator-page-size-label{
    color: white;
  }

  .mat-paginator, .mat-paginator-page-size .mat-select-trigger{
    color: white;
  }

  .mat-form-field-outline{
    background: #474747;
    border: 1px #FFFFFF solid;
    border-radius: 8px;
    color: #FFFFFF !important;
  }

  ::placeholder{
    color: #c4c4c4;
    padding-left: -20px;
  }

  .mat-input-element{
    color: white;
  }

  .mat-select-placeholder{
    color: #c4c4c4;
  }

  .mat-select-arrow{
    color: white;
  }

  .mat-form-field-label{
    color: white;
  }

  .mat-select-value, .mat-select-arrow{
    color: white !important;
  }

  .mat-form-field-underline {
    background-color: white !important;
  } 

  .mat-paginator-range-label{
    color: white !important;
  }
}

.dark-article-toolbar{
  button:not(.mat-primary){
    color: white
  }
}

.dark-chat-messages{
  .chat-message.right .chat-bubble{
    background-color: #121212 !important;
    .message{
      color: #ffffff;
    }

    .message-detail .username{
      color: #ffffff;
    }
  }

  .chat-message.right .chat-bubble:before{
    background-color: #121212 !important;
  }

  .chat-message.left .chat-bubble{
    background-color: #1964a0 !important;
    .message{
      color: #ffffff;
    }

    .message-detail .username{
      color: #ffffff;
    }
  }

  .chat-message.left .chat-bubble:before{
    background-color: #1964a0 !important;
  }
}

.dark-table{
  background: transparent;
  th{
    text-align: center;
    font-weight: 700;
    color: #FFFFFF !important;
    background: #383636 !important;
  }
  tr:nth-child(odd) {
      background: #484848 !important;
  }
  tr:nth-child(even) {
    background: #323232 !important;
  } 
  .row:nth-child(odd) {
    background: #383636 !important;
  }
  .row:nth-child(even) {
    background: #323232 !important;
  } 
}

.dark-suitability{
  .conversational-form{
    background: #383636;
  }
  ::before{
    background: linear-gradient(to bottom,#323232 0,rgba(255,255,255,0) 100%) !important;
  }
}

.dark-datepicker{
  background: #121212 !important;
  color: white !important;

  .mat-calendar-arrow{
    border-top-color: white !important;
  }
  .mat-datepicker-content{
    color: white !important;
  }

  .mat-calendar-previous-button{
    color: white;
  }

  .mat-calendar-previous-button[disabled]{
    color: gray;
  }

  .mat-calendar-next-button{
    color: white;
  }

  .mat-calendar-next-button[disabled]{
    color: gray;
  }

  .mat-calendar-table-header{
    color: white;
  }

  .mat-calendar-table-header-divider::after{
    background: white;
  }

  .mat-calendar-body-label{
    color: white;
  }

  .mat-calendar-body-cell-content{
    color: white;
  } :disabled {
    color: gray;
  }

  .mat-calendar-body-selected{
    background-color: #2D3A5C;
  }

  .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected){
    color: gray;
  }

  mat-form-field.break-border-top-mat-form-field-infix .mat-form-field-infix{
    border-top: 0.34375em solid transparent;
  }
}

.dark-mat-card{
  background: transparent;
}

.warning-products-bottom{
  padding: 10px 32px;
  text-align: justify;
  background-color: #DADADA;
  @media screen and (max-width: 500px) {
    padding: 10px 18px;
  }
  span{
    font-family: Raleway;
    font-size: 12px;
    line-height: 130%;

    @media screen and (max-width: 500px) {
        font-size: 10px;
    }
  }
}