// Momo
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

html {
  body {
    .mat-card-content, .mat-card-header .mat-card-title, .mat-card-subtitle {
      font-size: 14px;
      padding: 16px;
    }  
    .mat-card {
      padding: 16px;
      margin: 4px;
      @media screen and (max-width:557px){
        margin: 8px;
      }
      .mat-card-actions {
        margin-left: -16px;
        margin-right: -16px;
        padding: 8px 0;
      }
      &, & .mat-card {
        & .mat-card-actions:last-child {
          margin-bottom: -16px;
          margin-left: -16px;
          margin-right: -16px;
          padding: 8px 0;
        }
      }
    }
  }
}

// Custom
html body .mat-card {
  padding: 8px;
  .mat-card-title {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: bold;
    padding-left: 16px;
    padding-right: 16px;
  }
  .mat-card-title:not(:first) {
    margin-top: -8;
    padding-top: 0;
  }

  .mat-card-content {
      padding-top: 8px;
      padding-bottom: 8px;
      margin-bottom: 0px;
      p {
          margin-bottom: 0;
          margin-top: 4px;
          font-size: 12px;
      }
  }

  .mat-card-content:first-child {
      margin-top: 0;
  }
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
  .mat-card-footer .center {
    padding: 0 4px 4px 4px!important;
  }
}

@media screen and (min-width: 900px) and (max-width: 999px) {
  .mat-card-footer .center {
    padding: 0 8px 4px 8px!important;
  }
}
