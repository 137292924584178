@use '@angular/material' as mat;
@import "_sizes.scss";

html, body {
  margin: 0;
  width: 100%;
  height: 100%;
}

//make pages display block for route animation
router-outlet ~ * {
  display: block;
  width: 100%;
  height: 100%;
}

.page {
  // overflow-y: scroll;
  // overflow: hidden;
  // height: calc(100vh - 56px);
  min-height: calc(100vh - #{$footer-height});
}

.page-toolbar {
  transition: none !important;
    box-shadow: none !important;
  .mat-toolbar.mat-primary {
    background: linear-gradient(90deg, #217FF3 0%, #0E65D0 100%), #C4C4C4;
  }
  .mat-toolbar {
    transition: none !important;
    box-shadow: none !important;
    margin-bottom: 2px solid linear-gradient(90deg, #217FF3 0%, #0E65D0 100%), #C4C4C4;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
  }
}

.page-toolbar.custom {
  .mat-toolbar {
    z-index: 20;
  }
}

.page-section {
  padding: 90px 5vw 70px 5vw;
  min-height: calc(100vh - 200px);
}

.page-section-suitability{
  padding:  80px 20px 50px 20px;
  @media screen and (max-width:557px){
    padding: 80px 0 0 0;
  }
  @media screen and (min-width:558px) and (max-width: 960px) {
    padding:  70px 15px 40px 15px;
  }
  box-sizing: border-box;
  &.wrapper-box, &.wrapper-box-desktop {
    height: 100vh;
    padding: 80px 10vw 0 10vw;
    @media screen and (max-width: 900px) {
      padding: 80px 5vw 0 5vw; //A formatação dos espaçamentos da pagina trabalha em conjunto com o width do arquivo perfil-suitability-page.component.scss por volta da linha 39
    }
  }
}

.page.page-no-footer {
  min-height: 100vh;
}

body {
  * {
    -moz-user-select: text; 
    -webkit-user-select: text; 
    -ms-user-select: text; 
    user-select: text;
    -o-user-select: text;
  }

}

.info-footer {
  background-color: #e6e6e6;
  color: #888888;
  padding:  20px 20px 32px 20px;
  @media screen and (max-width: 960px) {
    padding:  15px 15px 24px 15px;
  }
}

/************************************** SIDE PANEL STYLES **************************************/
.mat-sidenav {
  width: 280px;
  @include mat.elevation(16);
}
.perfect-scrollbar {
  height: auto;
}

// .mat-sidenav-content {
//   overflow: hidden!important;
// }
// .page .mat-sidenav-content {
//   overflow-x: hidden!important;
//   overflow-y: scroll!important;
// }