// @import 'color-mixins';
@import "../_variables";
@import '../../../node_modules/@angular/material/theming';

@mixin snackbar-theme($theme) {
    button.mat-simple-snackbar-action {
        color: accent-color($theme);
    }
//   $backgroundColor: background-color($theme);
//   .mat-sidenav-container {
//     .mat-sidenav {
//       background-color: $backgroundColor;
//       px-gdn-sideitem div.item, px-gdn-sideitem i {
//         background-color: $backgroundColor;
//         color: white;
//       }
//       // px-gdn-sideitem div.item :hover {
//       //   color: accent-color($theme);
//       // }
//     }
//   }
}
