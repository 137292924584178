@use '@angular/material' as mat;
@import '../../../node_modules/@angular/material/theming';

$primary-text-color:   #212121;
$dark-primary-text-color:   white;
$light-app-primary: mat.define-palette(mat.$blue-palette, 500);
$light-app-accent:  mat.define-palette(mat.$pink-palette, A200);
$light-app-warn:    mat.define-palette(mat.$red-palette);

$light-app-theme: mat.define-light-theme($light-app-primary, $light-app-accent, $light-app-warn);
$dark-app-theme: mat.define-dark-theme($light-app-primary, $light-app-accent, $light-app-warn);
